import {MapContainer, Marker, TileLayer, Tooltip} from 'react-leaflet'
import BoxFullWidth from 'component/material/BoxFullWidth'
import React from 'react'
import Car from 'style/asset/car.png'
import L from 'leaflet'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import Typography from '@material-ui/core/Typography'
import columns from 'component/CallPage/supplierColumns'
import Box from '@material-ui/core/Box'
import StarIcon from '@material-ui/icons/Star'

import GoldPin from 'style/asset/markers/gold-marker.svg'

import RedMarkerOnePin from 'style/asset/markers/red-marker-1.svg'
import RedMarkerTwoPin from 'style/asset/markers/red-marker-2.svg'
import RedMarkerThreePin from 'style/asset/markers/red-marker-3.svg'
import RedMarkerNullPin from 'style/asset/markers/red-marker-null.svg'

import GreenMarkerOnePin from 'style/asset/markers/green-marker-1.svg'
import GreenMarkerTwoPin from 'style/asset/markers/green-marker-2.svg'
import GreenMarkerThreePin from 'style/asset/markers/green-marker-3.svg'
import GreenMarkerNullPin from 'style/asset/markers/green-marker-null.svg'

import YellowMarkerOnePin from 'style/asset/markers/yellow-marker-1.svg'
import YellowMarkerTwoPin from 'style/asset/markers/yellow-marker-2.svg'
import YellowMarkerThreePin from 'style/asset/markers/yellow-marker-3.svg'
import YellowMarkerNullPin from 'style/asset/markers/yellow-marker-null.svg'

const EstablishmentMap = (props) => {
  const {supplierData, pickUpCoords, selectSupplier} = props

  const redMarkers = [RedMarkerNullPin, RedMarkerOnePin, RedMarkerTwoPin, RedMarkerThreePin]
  const greenMarkers = [
    GreenMarkerNullPin,
    GreenMarkerOnePin,
    GreenMarkerTwoPin,
    GreenMarkerThreePin,
  ]
  const yellowMarkers = [
    YellowMarkerNullPin,
    YellowMarkerOnePin,
    YellowMarkerTwoPin,
    YellowMarkerThreePin,
  ]

  const LocationMarker = ({data}) => {
    return data?.map((m, i) => {
      const MarkerIcon =
        m.color === 'gold'
          ? GoldPin
          : m.color === 'green'
          ? greenMarkers[m.pref_level || 0]
          : m.color === 'red'
          ? redMarkers[m.pref_level || 0]
          : yellowMarkers[m.pref_level || 0]

      const myIcon = L.icon({
        iconUrl: MarkerIcon,
        iconSize: [40, 27],
        iconAnchor: [20, 27],
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
      })

      return (
        <Marker
          key={i}
          icon={myIcon}
          position={[Number(m?.location_x), Number(m?.location_y)]}
          eventHandlers={{
            click: () => selectSupplier(m.value),
          }}
        >
          <Tooltip>
            <GridContainer>
              <GridItem container xs={6}>
                {columns[0].map((c, i) => (
                  <GridContainer key={i} style={{paddingBottom: 4}}>
                    <GridItem xs={12}>
                      <Typography style={{fontSize: 12, fontWeight: 600}}>{c.label}:</Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography style={{fontSize: 12}}>
                        {c.name === 'mark' ? (
                          <Box style={{display: 'flex'}}>
                            {m?.mark?.name}
                            {m?.mark?.premium && (
                              <Box smtyle={{paddingLeft: 4, height: 18}}>
                                <StarIcon style={{width: 16, height: 16}} />
                              </Box>
                            )}
                          </Box>
                        ) : m?.[c.name] ? (
                          m?.[c.name]
                        ) : (
                          '--------'
                        )}
                      </Typography>
                    </GridItem>
                  </GridContainer>
                ))}
              </GridItem>
              <GridItem container xs={6}>
                {columns[1].map((c, i) => (
                  <GridContainer key={i} style={{paddingBottom: 4}}>
                    <GridItem xs={12}>
                      <Typography style={{fontSize: 12, fontWeight: 600}}>{c.label}:</Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography style={{fontSize: 12}}>
                        {m?.[c.name] ? m?.[c.name] : '--------'}
                      </Typography>
                    </GridItem>
                  </GridContainer>
                ))}
              </GridItem>
            </GridContainer>
          </Tooltip>
        </Marker>
      )
    })
  }

  const getCenter = () => {
    if (pickUpCoords.x && pickUpCoords.y) {
      return {lat: pickUpCoords.y, lng: pickUpCoords.x}
    } else {
      return {lat: 49.8175131, lng: 15.4766292}
    }
  }

  return (
    <BoxFullWidth style={{minHeight: 550}}>
      <MapContainer center={getCenter()} zoom={7} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {supplierData && <LocationMarker data={supplierData} />}
        {pickUpCoords.x && pickUpCoords.y && (
          <Marker
            icon={L.icon({
              iconUrl: Car,
              iconSize: [50, 50],
              iconAnchor: [25, 50],
              popupAnchor: null,
              shadowUrl: null,
              shadowSize: null,
              shadowAnchor: null,
            })}
            position={[pickUpCoords.y, pickUpCoords.x]}
          />
        )}
      </MapContainer>
    </BoxFullWidth>
  )
}

export default EstablishmentMap
