import GridItem from 'component/material/GridItem'
import Typography from '@material-ui/core/Typography'
import {Trans} from '@lingui/macro'
import {Field} from 'react-final-form'
import CheckBoxInput from 'component/field/CheckboxInput'
import TextInput from 'component/field/TextInput'
import React from 'react'

const ScopeOfRepair = (props) => {
  const {notEditableOrSubmitting} = props

  return (
    <>
      <GridItem container xs={12} justifyContent={'center'}>
        <Typography variant={'h6'} style={{fontWeight: 600, marginBottom: 10, marginTop: 20}}>
          <Trans>Scope of repair</Trans>
        </Typography>
      </GridItem>

      <GridItem container xs={12}>
        <GridItem container xs={6}>
          <Field
            disabled={notEditableOrSubmitting}
            name="service_visit"
            label={<Trans>Service visit</Trans>}
            component={CheckBoxInput}
            type={'checkbox'}
          />
        </GridItem>
        <GridItem container xs={6}>
          <Field
            disabled={notEditableOrSubmitting}
            name="lighting"
            label={<Trans>Lighting</Trans>}
            component={CheckBoxInput}
            type={'checkbox'}
          />
        </GridItem>
        <GridItem container xs={6}>
          <Field
            disabled={notEditableOrSubmitting}
            name="tire_service"
            label={<Trans>Tire service</Trans>}
            component={CheckBoxInput}
            type={'checkbox'}
          />
        </GridItem>
        <GridItem container xs={6}>
          <Field
            disabled={notEditableOrSubmitting}
            name="undercarriage"
            label={<Trans>Undercarriage</Trans>}
            component={CheckBoxInput}
            type={'checkbox'}
          />
        </GridItem>
        <GridItem container xs={6}>
          <Field
            disabled={notEditableOrSubmitting}
            name="brakes"
            label={<Trans>Brakes</Trans>}
            component={CheckBoxInput}
            type={'checkbox'}
          />
        </GridItem>
        <GridItem container xs={6}>
          <Field
            disabled={notEditableOrSubmitting}
            name="glasses"
            label={<Trans>Glasses</Trans>}
            component={CheckBoxInput}
            type={'checkbox'}
          />
        </GridItem>
        <GridItem container xs={6}>
          <Field
            disabled={notEditableOrSubmitting}
            name="electro"
            label={<Trans>Electro</Trans>}
            component={CheckBoxInput}
            type={'checkbox'}
          />
        </GridItem>
        <GridItem container xs={6}>
          <Field
            disabled={notEditableOrSubmitting}
            name="stk"
            label={<Trans>Technical inspection + emissions</Trans>}
            component={CheckBoxInput}
            type={'checkbox'}
          />
        </GridItem>
        <GridItem container xs={12} justifyContent={'flex-end'}>
          <GridItem xs={10}>
            <Field
              disabled={notEditableOrSubmitting}
              name="scope_of_repair"
              label={<Trans>Additional information for repair</Trans>}
              component={TextInput}
              multiline
              minRows={4}
              filters={true}
              longLabel={true}
            />
          </GridItem>
        </GridItem>
      </GridItem>
    </>
  )
}

export default ScopeOfRepair
