import {getServiceUrl} from 'helper/functions'

// map of endpoints for cleaner use in api calls
const endpoints = {
  login: `${getServiceUrl('auth')}/login`,
  logout: `${getServiceUrl('auth')}/logout`,
  forgottenPassword: `${getServiceUrl('auth')}/forgotten-password`,
  resetPassword: `${getServiceUrl('auth')}/reset-password`,
  currentUser: `${getServiceUrl('auth')}/current-user`,
  user: `${getServiceUrl('auth')}/users`,
  role: `${getServiceUrl('auth')}/roles`,

  calls: `${getServiceUrl('cc')}/calls`,
  statuses: `${getServiceUrl('cc')}/statuses`,
  fleets: `${getServiceUrl('cc')}/fleets`,
  marks: `${getServiceUrl('cc')}/marks`,
  carSearch: `${getServiceUrl('cc')}/car-search`,
  cars: `${getServiceUrl('cc')}/cars`,
  driverInfo: `${getServiceUrl('cc')}/driver-info-whisperer`,

  report: `${getServiceUrl('reporting')}/report-trigger`,
}

export default endpoints
