import React, {useCallback, useEffect, useState} from 'react'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {Trans} from '@lingui/macro'
import DialogWindow from 'component/material/DialogWindow'
import {Field, Form} from 'react-final-form'
import GridItem from 'component/material/GridItem'
import GridContainer from 'component/material/GridContainer'
import TextInput from 'component/field/TextInput'
import {Grow, List, ListItem, Popover} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import MapIcon from '@material-ui/icons/Map'
import withStyles from '@material-ui/core/styles/withStyles'
import detailPageStyle from 'component/detailPageStyle'
import Box from '@material-ui/core/Box'
import PrimaryButton from 'component/material/PrimaryButton'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import CallDetailPreferDates from 'component/CallPage/CallDetailPreferDates'
import {diff} from 'deep-object-diff'
import moment from 'moment/moment'
import {DATE_TIME_DISPLAY_FORMAT} from 'helper/constants'
import {handleSortByLabel, mapErrorResponseToForm} from 'helper/functions'
import {getOrderPdf, patchCall} from 'redux/action/callAction'
import EstablishmentMap from 'component/CallPage/EstablishmentMap'
import columns from 'component/CallPage/supplierColumns'
import AsyncInput from 'component/field/AsyncInput'
import EstablishmentHistory from 'component/CallPage/EstablishmentHistory'
import EstablishmentOrderEmail from 'component/CallPage/EstablishmentOrderEmail'
import StarIcon from '@material-ui/icons/Star'
import CustomTooltip from 'component/material/CustomTooltip'
import EstablishmentDriverOrderEmail from 'component/CallPage/EstablishmentDriverOrderEmail'

let debounceInterval = null

const EstablishmentDetail = (props) => {
  const {
    classes,
    setActiveStep,
    initialValues,
    patchCall,
    preferredTerms,
    getOrderPdf,
    isNotEditable,
  } = props

  let {id} = useParams()

  const isNew = id === 'new'

  const [supplierData, setSupplierData] = useState([])
  const [supplierLoading, setSupplierLoading] = useState(false)
  const [datesData, setDatesData] = useState([])
  const [openMapDialog, setOpenMapDialog] = useState(false)
  const [openOrderDialog, setOpenOrderDialog] = useState(false)
  const [openDriverOrderDialog, setOpenDriverOrderDialog] = useState(false)
  const [addressData, setAddressData] = useState({pickUp: [], dropOff: []})
  const [mapLoaderLoaded, setMapLoaderLoaded] = useState(false)
  const [pickUpCoords, setPickUpCoords] = useState({x: null, y: null})

  const handleSuggest = (e, id) => {
    JAK.Events.cancelDef(e)

    const suggest = new SMap.Suggest(JAK.gel(id))

    suggest.addListener('request-items', (items) => {
      setAddressData((prevState) => ({...prevState, [id]: items}))
    })
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const addressDebounce = useCallback((e, id) => {
    setAddressData((prevState) => ({...prevState, [id]: []}))
    setAnchorEl(e.currentTarget)
    clearTimeout(debounceInterval)
    debounceInterval = setTimeout(() => {
      handleSuggest(e, id)
    }, 250)
  }, [])

  const getAddressDetails = (address, form, prefix) => {
    const source = addressData?.[prefix === 'pick_up_' ? 'pickUp' : 'dropOff']?.find(
      (a) => a.longitude === address.coords.x && a.latitude === address.coords.y
    )?.source

    const addressItems = address.items
    let city, addr, street

    // for other than czech addresses SMaps uses internally OpenStreetMaps api
    if (source === 'osm') {
      city = addressItems?.find((i) => i.type === 'osmm')?.name
      addr = address.label
      street = `${addressItems?.find((i) => i.type === 'osms')?.name} ${
        addressItems?.find((i) => i.type === 'osmd')?.name
      }`
    } else if (source === 'addr') {
      city = addressItems?.find((i) => i.type === 'muni')?.name
      addr = addressItems?.find((i) => i.type === 'addr')?.name
      street = addressItems?.find((i) => i.type === 'stre')?.name
    }

    if (addr || street) form.change(`${prefix}address`, addr || street)
    if (city) form.change(`${prefix}city`, city)

    handleClose()
  }

  const onSelectAddressPickUp = (address, form) => {
    new SMap.Geocoder.Reverse(SMap.Coords.fromWGS84(address.longitude, address.latitude), (g) => {
      const result = g.getResults()
      getAddressDetails(result, form, 'pick_up_')
    })
  }

  const onSelectAddressDropOff = (address, form) => {
    new SMap.Geocoder.Reverse(SMap.Coords.fromWGS84(address.longitude, address.latitude), (g) => {
      const result = g.getResults()
      getAddressDetails(result, form, 'drop_off_')
    })
  }

  const geocodeFromAddress = (query) => {
    let result
    if (query) {
      new SMap.Geocoder(query, (res) => {
        result = res.getResults()[0].results
        const coords = result[0].coords
        const sMapCoords = new SMap.Coords(coords.x, coords.y)
        setPickUpCoords({x: sMapCoords.x, y: sMapCoords.y})
      })
    }
  }

  const handleCopyPickUp = (formProps) => {
    const values = formProps.values
    let change = formProps.form.change

    if (values.pick_up_company) change('drop_off_company', values.pick_up_company)
    if (values.pick_up_address) change('drop_off_address', values.pick_up_address)
    if (values.pick_up_city) change('drop_off_city', values.pick_up_city)
  }

  const loadSupplierOptions = async (search, loadedOptions) => {
    if (id !== 'new') {
      let data = [...loadedOptions]
      let hasMore = false
      await httpClient
        .get(`${endpoints.calls}/${id}/suppliers?order_by=name:ASC`, {
          limit: 100,
          offset: loadedOptions.length,
        })
        .then((res) => {
          data.push(
            ...res?.data?.objects.map((r) => ({
              value: r.id,
              label: r.name,
              ...r,
            }))
          )
          hasMore = res?.data?.meta?.total_count > loadedOptions.length
        })
      if (hasMore) {
        return await loadSupplierOptions('', data)
      } else {
        return {
          options: data,
          hasMore: hasMore,
        }
      }
    }
  }

  const getSupplierData = async (search) => {
    const filteredOptions = supplierData.filter((s) =>
      s.label.toLowerCase().includes(search.toLowerCase())
    )
    return {
      options: handleSortByLabel(
        filteredOptions?.map((s) => ({
          label: s.label,
          value: s.value,
        }))
      ),
      hasMore: false,
    }
  }

  const handleSelectSupplier = (supplierId, formProps) => {
    formProps.form.change('supplier_id', supplierId)
    setOpenMapDialog(false)
  }

  const handleEditCall = async (values) => {
    let diffData = diff(initialValues, values)

    Object.entries(diffData).forEach(([key, value]) => {
      if (value === undefined) {
        diffData[key] = null
      }
    })

    return await patchCall(id, {
      ...diffData,
      drop_off_terms: datesData.map((d) => ({
        priority: d.priority,
        datetime: moment(d.datetime, DATE_TIME_DISPLAY_FORMAT).utc().format(),
      })),
    })
      .then(() => {})
      .catch((err) => {
        return mapErrorResponseToForm(err)
      })
  }

  const handleDownloadPdf = (values) => {
    handleEditCall(values).then(() => {
      getOrderPdf(id).then((res) => {
        // create temp download url
        const downloadUrl = window.URL.createObjectURL(res)

        // open pdf file on new tab
        window.open(downloadUrl, '__blank')

        // remove temp url
        window.URL.revokeObjectURL(downloadUrl)
      })
    })
  }

  const handleOpenMap = (values) => {
    if (values.pick_up_city && values.pick_up_address && mapLoaderLoaded) {
      geocodeFromAddress(`${values.pick_up_city}, ${values.pick_up_address}`)
    }
    setOpenMapDialog(true)
  }

  useEffect(() => {
    // Pokud ještě nebyl script element vložen
    if (!document.getElementById('map-loader-script')) {
      // Vytvoříme script element s odkazem na loader
      const script = document.createElement('script')
      script.src = 'https://api.mapy.cz/loader.js'
      script.id = 'map-loader-script'

      // Po načtení scriptu spustíme loader
      script.onload = () => {
        Loader.async = true
        Loader.load(null, {suggest: true}, () => {
          setMapLoaderLoaded(true)
        })
      }
      // Script element přidáme do hlavičky
      document.head.append(script)
    } else {
      // Loader již byl vložen dříve
      setMapLoaderLoaded(true)
    }
  }, [])

  const isMapLoaderLoaded = () => mapLoaderLoaded && typeof SMap !== 'undefined'

  const handleOpenOrderDialog = (values) => {
    handleEditCall(values).then(() => {
      setOpenOrderDialog(true)
    })
  }

  const handleCloseOrderDialog = () => {
    setOpenOrderDialog(false)
  }

  const handleOpenDriverOrderDialog = (values) => {
    handleEditCall(values).then(() => {
      setOpenDriverOrderDialog(true)
    })
  }

  const handleCloseDriverOrderDialog = () => {
    setOpenDriverOrderDialog(false)
  }

  useEffect(() => {
    if (isMapLoaderLoaded()) {
      new SMap(JAK.gel('map'))
    }
  }, [mapLoaderLoaded])

  useEffect(() => {
    if (initialValues.pick_up_city && initialValues.pick_up_address && mapLoaderLoaded) {
      geocodeFromAddress(`${initialValues.pick_up_city}, ${initialValues.pick_up_address}`)
    }
  }, [initialValues, mapLoaderLoaded])

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setSupplierLoading(true)
    loadSupplierOptions('', [])
      .then((res) => setSupplierData(res?.options))
      .finally(() => setSupplierLoading(false))
  }, [])

  useEffect(() => {
    if (preferredTerms) {
      setDatesData(
        preferredTerms.map((d) => ({
          priority: d.priority,
          datetime: moment(d.datetime).format(DATE_TIME_DISPLAY_FORMAT),
        }))
      )
    }
  }, [preferredTerms])

  const onAsyncChange = (formProps) => (name, value) => {
    formProps.form.change(name, value)
  }

  const notEditableOrSubmitting = (formProps) => isNotEditable || formProps.submitting

  return (
    <BoxFullWidth>
      <Form onSubmit={handleEditCall} initialValues={{...initialValues}}>
        {(formProps) => {
          const selectedSupplier = supplierData?.find(
            (s) => s.value === formProps.values.supplier_id
          )

          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                <GridContainer
                  item
                  xs={6}
                  style={{borderRight: '1px solid #bdbdbd', padding: '20px 40px 20px 20px'}}
                  alignContent={'flex-start'}
                >
                  <GridItem container justifyContent={'center'}>
                    <Typography variant={'h6'} style={{fontWeight: 600, marginBottom: 10}}>
                      <Trans>Pick up</Trans>
                    </Typography>
                  </GridItem>
                  <GridItem style={{minHeight: 42}} xs={12} />
                  <GridItem container xs={12}>
                    <Field
                      disabled={notEditableOrSubmitting(formProps) || isNew}
                      name="pick_up_company"
                      label={<Trans>Company</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={notEditableOrSubmitting(formProps) || isNew}
                      name="pick_up_address"
                      label={<Trans>Address</Trans>}
                      component={TextInput}
                      id="pickUp"
                      onChange={(e) => addressDebounce(e, 'pickUp')}
                      autoComplete={'pick-address-off'}
                    />
                    {addressData?.pickUp?.length !== 0 && (
                      <Popover
                        disableAutoFocus={true}
                        disableEnforceFocus={true}
                        disablePortal={true}
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <List
                          dense={true}
                          disablePadding={true}
                          style={{width: document.querySelector('#pickUp')?.clientWidth - 80}}
                        >
                          {addressData?.pickUp?.map((a, i) => (
                            <ListItem
                              key={i}
                              button
                              disableGutters={true}
                              onClick={() => onSelectAddressPickUp(a, formProps.form)}
                            >
                              <GridContainer style={{paddingLeft: 8, paddingRight: 8}}>
                                <GridItem xs={12}>
                                  <Typography style={{fontSize: 12}}>{a.phrase}</Typography>
                                </GridItem>
                                <GridItem xs={12}>
                                  <Typography style={{fontSize: 11}}>{a.secondRow}</Typography>
                                </GridItem>
                              </GridContainer>
                            </ListItem>
                          ))}
                        </List>
                      </Popover>
                    )}
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={notEditableOrSubmitting(formProps) || isNew}
                      name="pick_up_city"
                      label={<Trans>City</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={notEditableOrSubmitting(formProps) || isNew}
                      name="email"
                      label={<Trans>E-mail</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer item xs={6} style={{padding: '20px 20px 20px 40px'}}>
                  <GridItem container justifyContent={'center'}>
                    <Typography variant={'h6'} style={{fontWeight: 600, marginBottom: 10}}>
                      <Trans>Return</Trans>
                    </Typography>
                  </GridItem>
                  <GridItem container xs={12} style={{height: 42}} alignItems={'center'}>
                    <GridItem
                      style={{
                        width: 180,
                        fontSize: 12,
                        fontWeight: 600,
                        lineHeight: 1.1,
                        textAlign: 'end',
                        color: '#585b6c',
                        paddingRight: 20,
                      }}
                    >
                      <Trans>Same as pick up</Trans>
                    </GridItem>
                    <GridItem>
                      <PrimaryButton
                        disabled={notEditableOrSubmitting(formProps)}
                        variant={'outlined'}
                        formSubmit={true}
                        fullWidth={false}
                        onClick={() => handleCopyPickUp(formProps)}
                        textPadding={0}
                        text={<Trans>Copy</Trans>}
                      />
                    </GridItem>
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={notEditableOrSubmitting(formProps) || isNew}
                      name="drop_off_company"
                      label={<Trans>Company</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={notEditableOrSubmitting(formProps) || isNew}
                      name="drop_off_address"
                      label={<Trans>Address</Trans>}
                      component={TextInput}
                      id="dropOff"
                      onChange={(e) => addressDebounce(e, 'dropOff')}
                      autoComplete={'drop-address-off'}
                    />
                    {addressData?.dropOff?.length !== 0 && (
                      <Popover
                        disableAutoFocus={true}
                        disableEnforceFocus={true}
                        disablePortal={true}
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <List
                          dense={true}
                          disablePadding={true}
                          style={{width: document.querySelector('#dropOff')?.clientWidth - 80}}
                        >
                          {addressData?.dropOff?.map((a, i) => (
                            <ListItem
                              key={i}
                              button
                              disableGutters={true}
                              onClick={() => onSelectAddressDropOff(a, formProps.form)}
                            >
                              <GridContainer style={{paddingLeft: 8, paddingRight: 8}}>
                                <GridItem xs={12}>
                                  <Typography style={{fontSize: 12}}>{a.phrase}</Typography>
                                </GridItem>
                                <GridItem xs={12}>
                                  <Typography style={{fontSize: 11}}>{a.secondRow}</Typography>
                                </GridItem>
                              </GridContainer>
                            </ListItem>
                          ))}
                        </List>
                      </Popover>
                    )}
                  </GridItem>

                  <GridItem container xs={12}>
                    <Field
                      disabled={notEditableOrSubmitting(formProps) || isNew}
                      name="drop_off_city"
                      label={<Trans>City</Trans>}
                      component={TextInput}
                    />
                  </GridItem>

                  <GridItem container xs={12} style={{marginTop: 20, marginBottom: 20}}>
                    <CallDetailPreferDates
                      disabled={notEditableOrSubmitting(formProps) || isNew}
                      classes={classes}
                      formProps={formProps}
                      data={datesData}
                      setData={setDatesData}
                      label={<Trans>Drop off terms</Trans>}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer
                  style={{borderTop: '1px solid #bdbdbd', marginTop: 40, paddingTop: 40}}
                >
                  <GridContainer item xs={12}>
                    <GridItem container justifyContent={'center'} id={'supplier-item'}>
                      <Typography variant={'h6'} style={{fontWeight: 600, marginBottom: 16}}>
                        <Trans>Supplier information</Trans>
                      </Typography>
                    </GridItem>
                    <GridContainer item xs={6} alignContent={'flex-start'}>
                      <GridItem xs={12} style={{paddingRight: 20}}>
                        <AsyncInput
                          isClearable={false}
                          name="supplier_id"
                          label={<Trans>Supplier</Trans>}
                          isSearchable={true}
                          loadOptions={getSupplierData}
                          onChange={onAsyncChange(formProps)}
                          value={{value: selectedSupplier?.id, label: selectedSupplier?.name}}
                          disabled={notEditableOrSubmitting(formProps) || isNew || supplierLoading}
                          loading={supplierLoading}
                          showHelperText={true}
                          filters={false}
                        />
                      </GridItem>

                      <GridContainer
                        item
                        xs={12}
                        style={{height: 42, marginTop: 10}}
                        alignItems={'center'}
                      >
                        <GridItem
                          style={{
                            width: 180,
                            fontSize: 12,
                            fontWeight: 600,
                            lineHeight: 1.1,
                            textAlign: 'end',
                            color: '#585b6c',
                            paddingRight: 20,
                          }}
                        >
                          <Trans>Order form</Trans>
                        </GridItem>
                        <GridItem>
                          <PrimaryButton
                            disabled={
                              formProps.submitting || isNew || !formProps.values.supplier_id
                            }
                            fullWidth={false}
                            textPadding={0}
                            text={<Trans>Download</Trans>}
                            size={'small'}
                            onClick={() => handleDownloadPdf(formProps.values)}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer item xs={12} style={{height: 42}} alignItems={'center'}>
                        <GridItem
                          style={{
                            width: 180,
                            fontSize: 12,
                            fontWeight: 600,
                            lineHeight: 1.1,
                            textAlign: 'end',
                            color: '#585b6c',
                            paddingRight: 20,
                          }}
                        >
                          <Trans>Send order to service</Trans>
                        </GridItem>
                        <GridItem>
                          <PrimaryButton
                            disabled={
                              formProps.submitting || isNew || !formProps.values.supplier_id
                            }
                            fullWidth={false}
                            textPadding={0}
                            text={<Trans>Prepare e-mail</Trans>}
                            size={'small'}
                            onClick={() => handleOpenOrderDialog(formProps.values)}
                          />
                        </GridItem>

                        {/* DRIVER ORDER DIALOG */}
                        <DialogWindow
                          title={<Trans>Send order to service</Trans>}
                          fullWidth={true}
                          maxWidth={'md'}
                          open={openOrderDialog}
                          onClose={handleCloseOrderDialog}
                        >
                          <EstablishmentOrderEmail onClose={handleCloseOrderDialog} />
                        </DialogWindow>
                      </GridContainer>

                      <GridContainer
                        item
                        xs={12}
                        style={{height: 42, marginBottom: 10}}
                        alignItems={'center'}
                      >
                        <GridItem
                          style={{
                            width: 180,
                            fontSize: 12,
                            fontWeight: 600,
                            lineHeight: 1.1,
                            textAlign: 'end',
                            color: '#585b6c',
                            paddingRight: 20,
                          }}
                        >
                          <Trans>Send order to driver</Trans>
                        </GridItem>
                        <GridItem>
                          <PrimaryButton
                            disabled={
                              formProps.submitting || isNew || !formProps.values.supplier_id
                            }
                            fullWidth={false}
                            textPadding={0}
                            text={<Trans>Prepare e-mail</Trans>}
                            size={'small'}
                            onClick={() => handleOpenDriverOrderDialog(formProps.values)}
                          />
                        </GridItem>

                        {/* ORDER DIALOG */}
                        <DialogWindow
                          title={<Trans>Send order to driver</Trans>}
                          fullWidth={true}
                          maxWidth={'md'}
                          open={openDriverOrderDialog}
                          onClose={handleCloseDriverOrderDialog}
                        >
                          <EstablishmentDriverOrderEmail onClose={handleCloseDriverOrderDialog} />
                        </DialogWindow>
                      </GridContainer>

                      {selectedSupplier?.value && (
                        <Grow in={!!selectedSupplier?.value}>
                          <GridContainer
                            style={{
                              backgroundColor: '#dddddd',
                              marginRight: 20,
                              padding: 20,
                              borderRadius: 4,
                            }}
                          >
                            <GridItem container xs={6}>
                              {columns[0].map((c, i) => (
                                <GridContainer key={i} style={{paddingBottom: 4}}>
                                  <GridItem xs={12}>
                                    <Typography style={{fontSize: 12, fontWeight: 600}}>
                                      {c.label}:
                                    </Typography>
                                  </GridItem>
                                  <GridItem xs={12}>
                                    <Typography style={{fontSize: 12}}>
                                      {c.name === 'mark' ? (
                                        <Box style={{display: 'flex'}}>
                                          {selectedSupplier?.mark?.name}
                                          {selectedSupplier?.mark?.premium && (
                                            <Box style={{paddingLeft: 4, height: 18}}>
                                              <CustomTooltip title={<Trans>Premium</Trans>}>
                                                <StarIcon style={{width: 16, height: 16}} />
                                              </CustomTooltip>
                                            </Box>
                                          )}
                                        </Box>
                                      ) : selectedSupplier?.[c.name] ? (
                                        selectedSupplier?.[c.name]
                                      ) : (
                                        '--------'
                                      )}
                                    </Typography>
                                  </GridItem>
                                </GridContainer>
                              ))}
                            </GridItem>
                            <GridItem container xs={6}>
                              {columns[1].map((c, i) => (
                                <GridContainer key={i} style={{paddingBottom: 4}}>
                                  <GridItem xs={12}>
                                    <Typography style={{fontSize: 12, fontWeight: 600}}>
                                      {c.label}:
                                    </Typography>
                                  </GridItem>
                                  <GridItem xs={12}>
                                    <Typography style={{fontSize: 12}}>
                                      {selectedSupplier?.[c.name]
                                        ? selectedSupplier?.[c.name]
                                        : '--------'}
                                    </Typography>
                                  </GridItem>
                                </GridContainer>
                              ))}
                            </GridItem>
                          </GridContainer>
                        </Grow>
                      )}
                    </GridContainer>

                    <GridContainer
                      item
                      xs={6}
                      style={{paddingLeft: 20}}
                      alignContent={'flex-start'}
                    >
                      <GridItem container xs={12} className={classes.establishmentMapIcon}>
                        <Typography
                          style={{
                            fontSize: 12,
                            height: 30,
                            color: '#585b6c',
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 600,
                            paddingRight: 10,
                          }}
                        >
                          <Trans>Find on map</Trans>
                        </Typography>
                        <MapIcon onClick={() => handleOpenMap(formProps.values)} />
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </GridContainer>
              </GridContainer>

              <GridContainer
                style={{borderTop: '1px solid #bdbdbd', marginTop: 40, paddingTop: 40}}
              >
                <GridContainer item xs={12}>
                  <EstablishmentHistory
                    selectSupplier={(id) => {
                      !notEditableOrSubmitting(formProps) && handleSelectSupplier(id, formProps)
                    }}
                  />
                </GridContainer>
              </GridContainer>

              <GridContainer justifyContent={'space-between'} style={{paddingTop: 40}}>
                <Box style={{paddingRight: 20}}>
                  <PrimaryButton
                    variant={'outlined'}
                    fullWidth={false}
                    textPadding={0}
                    text={<Trans>Back to call information</Trans>}
                    size={'small'}
                    onClick={() => {
                      document.getElementById('save-button')?.click()
                      setActiveStep(0)
                    }}
                  />
                </Box>

                <PrimaryButton
                  id={'save-button'}
                  fullWidth={false}
                  textPadding={0}
                  text={<Trans>Save</Trans>}
                  size={'small'}
                  type={'submit'}
                  disabled={formProps.submitting || isNew}
                />
              </GridContainer>

              <div id="map" style={{height: 0, width: 0, display: 'none'}} />
              {/* MAP DIALOG */}
              <DialogWindow
                title={<Trans>Suppliers map</Trans>}
                fullWidth={true}
                open={openMapDialog}
                onClose={() => setOpenMapDialog(false)}
              >
                <EstablishmentMap
                  disabled={true}
                  supplierData={supplierData}
                  pickUpCoords={pickUpCoords}
                  selectSupplier={(id) => {
                    !notEditableOrSubmitting(formProps) && handleSelectSupplier(id, formProps)
                  }}
                />
              </DialogWindow>
            </form>
          )
        }}
      </Form>
    </BoxFullWidth>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      patchCall,
      getOrderPdf,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(EstablishmentDetail)
