export const FORCE_NO_AUTH = true
export const MOCK_API = false

export const MOCK_PORT_MAPPING = {
  cc: 8000,
}

// names of local storage items used for private routes, auth, etc.
export const LS_SESSION_ID_NAME = 'ccSessionId'
export const LS_LOGGED_USER = 'ccLoggedUser'
export const LS_GLOBAL_SETTINGS = 'ccGlobalSettings'
