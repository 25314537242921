import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import componentStyle from 'component/componentStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import Table from 'component/material/table/Table'
import React, {useEffect, useRef, useState} from 'react'
import {Divider} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import GridContainer from 'component/material/GridContainer'
import PrimaryButton from 'component/material/PrimaryButton'
import GridItem from 'component/material/GridItem'
import {redirectTo} from 'helper/functions'
import {connect} from 'react-redux'
import {getCallList, triggerReport} from 'redux/action/callAction'
import moment from 'moment'
import {DATE_TIME_DISPLAY_FORMAT} from 'helper/constants'
import CustomTooltip from 'component/material/CustomTooltip'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import TextInput from 'component/field/TextInput'
import NoteIcon from '@material-ui/icons/Note'
import DialogWindow from 'component/material/DialogWindow'
import CallPageNoteEdit from 'component/CallPage/CallPageNoteEdit'

let searchInterval = null

const CallPage = (props) => {
  const {classes, callData, callMeta, callLoading, getCallList, rowsPerPage, triggerReport} = props
  const [tableSort, setTableSort] = useState(
    JSON.parse(localStorage.getItem('ccTableSort')) || {orderBy: 'id', orderDirection: 'DESC'}
  )
  const [page, setPage] = useState(JSON.parse(localStorage.getItem('ccPage')) || 0)
  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem('ccFilters')) || {
      inProcess: false,
      closed: false,
      myCalls: false,
    }
  )
  const [searchValue, setSearchValue] = useState(null)
  const [noteDialog, setNoteDialog] = useState({open: false, data: null})
  const inputRef = useRef(null)

  const handleCloseDialog = () => {
    setNoteDialog({open: false, data: null})
  }

  const handleChangePage = (newPage) => {
    localStorage.setItem('ccPage', JSON.stringify(newPage))
    setPage(newPage)
  }

  const handleTableSort = (tableSort) => {
    localStorage.setItem('ccTableSort', JSON.stringify(tableSort))
    setTableSort(tableSort)
  }

  const debounceSearch = (value) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      localStorage.setItem('ccSearch', JSON.stringify(value))
      localStorage.setItem('ccPage', JSON.stringify(0))
      setPage(0)
      setSearchValue(value)
    }, 500)
  }

  const handleSearchChange = (e) => {
    debounceSearch(e.target.value)
  }

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      label: <Trans>ID</Trans>,
    },
    {
      name: 'registration_plate',
      label: <Trans>Registration plate</Trans>,
    },
    {
      name: 'date_of_call',
      sortKey: 'date_of_call',
      label: <Trans>Date and time of call</Trans>,
      render: (val) => val && moment(val).format(DATE_TIME_DISPLAY_FORMAT),
    },
    {
      name: 'pick_up',
      sortKey: 'pick_up',
      label: <Trans>Pick-up</Trans>,
      render: (val) => (val ? <Trans>Yes</Trans> : <Trans>No</Trans>),
    },
    {
      name: 'notification_number',
      sortKey: 'notification_number',
      label: <Trans>Notification number</Trans>,
    },
    {
      name: 'status_name',
      sortKey: 'status',
      label: <Trans>Status</Trans>,
    },
    {
      name: 'fleet_name',
      label: <Trans>Fleet</Trans>,
    },
    {
      name: 'time_since_creation',
      label: <Trans>Time</Trans>,
    },
    {
      name: 'type',
      label: <Trans>Type</Trans>,
    },
    {
      name: 'note',
      sortKey: 'note',
      label: <Trans>Note</Trans>,
      render: (val, row) => (
        <>
          <CustomTooltip title={val || ''}>
            <GridContainer
              alignItems={'center'}
              className={classes.editNoteCell}
              onClick={(e) => {
                e.stopPropagation()
                setNoteDialog({open: true, data: row})
              }}
            >
              <NoteIcon className={classes.editNoteIcon} />
            </GridContainer>
          </CustomTooltip>
          <p
            style={{
              margin: 0,
              padding: 0,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: 350,
              textOverflow: 'ellipsis',
              paddingLeft: 18,
            }}
          >
            {val}
          </p>
        </>
      ),
    },
    {
      name: 'operator_name',
      sortKey: 'operator_name',
      label: <Trans>Operator</Trans>,
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '40px',
      render: (val, row) => (
        <div className={classes.editIcon} onClick={openDetail(row)}>
          <Edit />
        </div>
      ),
    },
  ]

  const openDetail = (row) => () => {
    redirectTo(`/call/${row.id}`)
  }

  const onFilterClick = (filter) => {
    if (filter === 'all') {
      localStorage.setItem(
        'ccFilters',
        JSON.stringify({inProcess: filters?.inProcess, closed: filters?.closed, myCalls: false})
      )
      setFilters((prevState) => ({...prevState, myCalls: !prevState.myCalls}))
    } else if (filter === 'closed') {
      localStorage.setItem(
        'ccFilters',
        JSON.stringify({inProcess: false, closed: !filters?.closed, myCalls: filters?.myCalls})
      )
      setFilters((prevState) => ({
        ...prevState,
        closed: !prevState.closed,
        inProcess: false,
      }))
    } else if (filter === 'myCalls') {
      localStorage.setItem(
        'ccFilters',
        JSON.stringify({
          inProcess: filters?.inProcess,
          closed: filters?.closed,
          myCalls: !filters?.myCalls,
        })
      )
      setFilters((prevState) => ({...prevState, myCalls: !prevState.myCalls}))
    } else if (filter === 'inProcess') {
      localStorage.setItem(
        'ccFilters',
        JSON.stringify({
          inProcess: !filters?.inProcess,
          closed: false,
          myCalls: filters?.myCalls,
        })
      )
      setFilters((prevState) => ({
        ...prevState,
        inProcess: !prevState.inProcess,
        closed: false,
      }))
    }
    localStorage.setItem('ccPage', JSON.stringify(0))
    setPage(0)
  }

  const handleRowClick = (row) => {
    redirectTo(`/call/${row.id}`)
  }

  const handleGetCallList = () => {
    const lsFilters = localStorage.getItem('ccFilters')
    const lsSearch = localStorage.getItem('ccSearch')
    const lsPage = localStorage.getItem('ccPage')
    const lsTableSort = localStorage.getItem('ccTableSort')

    let stateFilters = filters
    let stateSearch = searchValue
    let statePage = page
    let stateTableSort = tableSort

    if (lsFilters !== JSON.stringify(filters)) {
      stateFilters = JSON.parse(lsFilters)
    }
    if (lsSearch !== JSON.stringify(searchValue)) {
      inputRef.current.value = JSON.parse(lsSearch)
      stateSearch = JSON.parse(lsSearch)
    }
    if (lsPage && lsPage !== JSON.stringify(page)) {
      statePage = JSON.parse(lsPage)
    }
    if (lsTableSort !== JSON.stringify(tableSort)) {
      stateTableSort = JSON.parse(lsTableSort)
    }

    getCallList(rowsPerPage, statePage * rowsPerPage, stateTableSort, stateFilters, stateSearch)
  }

  useEffect(() => {
    handleGetCallList()

    return () => {
      clearTimeout(searchInterval)
    }
  }, [rowsPerPage, page, filters, tableSort, searchValue])

  return (
    <>
      {/* NOTE EDIT DIALOG */}
      <DialogWindow
        title={<Trans>Edit call note</Trans>}
        fullWidth={true}
        maxWidth={'sm'}
        open={noteDialog.open}
        onClose={handleCloseDialog}
      >
        <CallPageNoteEdit
          data={noteDialog.data}
          onClose={handleCloseDialog}
          getCalls={handleGetCallList}
        />
      </DialogWindow>

      <BoxFullWidth>
        <div className={classes.listPageControlContainer}>
          <GridContainer xs={true} item spacing={2} justifyContent={'flex-start'}>
            {/*<GridItem>*/}
            {/*  <PrimaryButton*/}
            {/*    size={'small'}*/}
            {/*    variant={'contained'}*/}
            {/*    fullWidth={false}*/}
            {/*    textPadding={0}*/}
            {/*    text={<Trans>OTH report</Trans>}*/}
            {/*    onClick={() => triggerReport('oth_report')}*/}
            {/*    className={classes.callFilterButtons}*/}
            {/*  />*/}
            {/*</GridItem>*/}
            <GridItem>
              <PrimaryButton
                size={'small'}
                variant={'contained'}
                fullWidth={false}
                textPadding={0}
                text={<Trans>BL report</Trans>}
                onClick={() => triggerReport('bl_report')}
                className={classes.callFilterButtons}
              />
            </GridItem>
            <GridItem>
              <PrimaryButton
                size={'small'}
                variant={'contained'}
                fullWidth={false}
                textPadding={0}
                text={<Trans>Audit report</Trans>}
                onClick={() => triggerReport('audit_report')}
                className={classes.callFilterButtons}
              />
            </GridItem>
          </GridContainer>
          <div>
            <PrimaryButton
              fullWidth={false}
              textPadding={0}
              text={<Trans>New call</Trans>}
              onClick={() => redirectTo('/call/new')}
              className={classes.newCallButton}
            />
          </div>
        </div>
      </BoxFullWidth>

      <BoxFullWidth>
        <div className={classes.listPageControlContainer}>
          <div className={classes.searchContainer}>
            <div className={classes.searchLabel}>
              <Trans>Search:</Trans>
            </div>
            <TextInput
              inputRef={inputRef}
              onChange={handleSearchChange}
              loading={callLoading}
              className={classes.searchInput}
              showHelperText={false}
            />
          </div>
          <GridContainer xs={true} item spacing={2} justifyContent={'flex-end'}>
            <GridItem>
              <PrimaryButton
                size={'small'}
                variant={filters?.inProcess ? 'contained' : 'outlined'}
                fullWidth={false}
                textPadding={0}
                text={<Trans>In process</Trans>}
                onClick={() => onFilterClick('inProcess')}
                className={classes.callFilterButtons}
              />
            </GridItem>
            <GridItem>
              <PrimaryButton
                size={'small'}
                variant={filters?.closed ? 'contained' : 'outlined'}
                fullWidth={false}
                textPadding={0}
                text={<Trans>Closed</Trans>}
                onClick={() => onFilterClick('closed')}
                className={classes.callFilterButtons}
              />
            </GridItem>
            <Divider orientation="vertical" flexItem style={{marginLeft: 8, marginRight: 8}} />
            <GridItem>
              <PrimaryButton
                size={'small'}
                variant={filters?.myCalls ? 'contained' : 'outlined'}
                fullWidth={false}
                textPadding={0}
                text={<Trans>My calls</Trans>}
                onClick={() => onFilterClick('myCalls')}
                className={classes.callFilterButtons}
              />
            </GridItem>
            <GridItem>
              <PrimaryButton
                size={'small'}
                variant={filters?.myCalls ? 'outlined' : 'contained'}
                fullWidth={false}
                textPadding={0}
                text={<Trans>All</Trans>}
                onClick={() => onFilterClick('all')}
                className={classes.callFilterButtons}
              />
            </GridItem>
          </GridContainer>
        </div>
      </BoxFullWidth>

      <BoxFullWidth>
        <div className={classes.overFlowAuto}>
          <Table
            data={callData}
            columns={columns}
            loading={callLoading}
            onTableColumnSort={handleTableSort}
            onTableRowClick={handleRowClick}
            tableSort={tableSort}
            page={page}
            meta={callMeta}
            onChangePage={handleChangePage}
          />
        </div>
      </BoxFullWidth>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCallList,
      triggerReport,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      callData: store.call.callListData,
      callLoading: store.call.callListLoading,
      callMeta: store.call.callListMeta,
      rowsPerPage: store.globalSettings.rowsPerPage,
    }
  }, mapDispatchToProps)
)(CallPage)
