import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PrivateLayoutTopBar from 'layout/PrivateLayout/PrivateLayoutTopBar'
import {getCurrentUser} from 'redux/action/authAction'

const PrivateLayout = (props) => {
  const {classes, children, getCurrentUser, currentUser, currentUserLoading} = props

  useEffect(() => {
    getCurrentUser()
  }, [])

  return (
    <div className={classes.root}>
      <PrivateLayoutTopBar loading={currentUserLoading} currentUser={currentUser} />

      <main className={classes.content}>{children}</main>
    </div>
  )
}

PrivateLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  getCurrentUser: PropTypes.func,
  currentUser: PropTypes.object,
  currentUserLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCurrentUser,
    },
    dispatch
  )
}

export default compose(
  withStyles(privateLayoutStyle),
  connect((store) => {
    return {
      currentUser: store.auth.currentUser,
      currentUserLoading: store.auth.currentUserLoading,
    }
  }, mapDispatchToProps)
)(PrivateLayout)
