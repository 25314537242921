import React from 'react'
import {DatePicker} from '@material-ui/pickers'
import fieldStyle from 'component/field/fieldStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import {DATE_FORMAT} from 'helper/constants'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import cx from 'classnames'
import InputAdornment from '@material-ui/core/InputAdornment'
import {ReactComponent as Calendar} from 'style/asset/calendar.svg'

const DatePickerInput = (props) => {
  const {
    classes,
    helperText,
    input,
    meta,
    label,
    inputVariant = 'outlined',
    showHelperText = true,
    clearable,
    onChange,
    disabled,
    filters,
    showStartAdornment = true,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  return (
    <div className={cx(classes.fullWidth, classes.inLineField)}>
      {label && <div className={cx(classes.fieldLabel)}>{label}</div>}
      <div
        className={cx(
          classes.helperText,
          classes.datePickerInput,
          classes.datePickerEndAdornmentPadding,
          classes.inputStyles,
          disabled && classes.disabledDatePicker
        )}
      >
        <DatePicker
          {...input}
          {...rest}
          value={input?.value ? input.value : null}
          inputVariant={inputVariant}
          disabled={disabled}
          autoOk={true}
          format={DATE_FORMAT}
          error={!!helperText}
          helperText={errorHelperText}
          invalidDateMessage={<Trans>Invalid Date Format</Trans>}
          maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
          minDateMessage={<Trans>Date should not be before minimal date</Trans>}
          cancelLabel={<Trans>Close</Trans>}
          okLabel={<Trans>Ok</Trans>}
          clearLabel={<Trans>Reset</Trans>}
          clearable={true}
          onChange={(e) => {
            input?.onChange(e)
            onChange && onChange({target: {value: e, name: input?.name}})
          }}
          InputProps={{
            startAdornment: showStartAdornment ? (
              <InputAdornment position={'start'} className={classes.datePickerAdornment}>
                <Calendar />
              </InputAdornment>
            ) : (
              <></>
            ),
          }}
        />
      </div>
    </div>
  )
}

DatePickerInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  variant: PropTypes.string,
  showHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  invalidDateMessage: PropTypes.node,
  maxDateMessage: PropTypes.node,
  minDateMessage: PropTypes.node,
  cancelLabel: PropTypes.node,
  okLabel: PropTypes.node,
}

export default withStyles(fieldStyle)(DatePickerInput)
