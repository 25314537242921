import React, {useState} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import PropTypes from 'prop-types'
import TableRow from 'component/material/table/TableRow'
import materialStyle from 'component/material/materialStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import cx from 'classnames'
import CustomTooltip from 'component/material/CustomTooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import {Trans} from '@lingui/macro'
import BoxFullWidth from 'component/material/BoxFullWidth'
import Loader from 'component/material/Loader'
import TablePagination from 'component/material/table/TablePagination'

const CustomTable = (props) => {
  const {
    classes,
    columns,
    data,
    size,
    onTableRowClick,
    onTableColumnSort,
    tableSort,
    loading,
    page,
    meta,
    onChangePage,
    pagination = true,
    className,
    getCellClass,
    customRowsPerPageOptions = null,
  } = props

  const [orderDirection, setOrderDirection] = useState(tableSort?.orderDirection)
  const [orderBy, setOrderBy] = useState(tableSort?.orderBy)

  const rowClick = (row) => () => {
    onTableRowClick(row)
  }

  const onSortClick = (sortKey) => () => {
    if (orderBy === sortKey && orderDirection === 'ASC') {
      setOrderDirection('DESC')
      setOrderBy(sortKey)
      onTableColumnSort({orderBy: sortKey, orderDirection: 'DESC'})
    } else if (orderBy === sortKey && orderDirection === 'DESC') {
      setOrderDirection('ASC')
      setOrderBy(null)
      onTableColumnSort({orderBy: null, orderDirection: 'ASC'})
    } else {
      setOrderDirection('ASC')
      setOrderBy(sortKey)
      onTableColumnSort({orderBy: sortKey, orderDirection: 'ASC'})
    }
  }

  return (
    columns && (
      <Table aria-label="table" className={cx(classes.tableClass, className)}>
        <TableHead>
          <TableRow>
            {columns?.map((col, index) => (
              <TableCell
                key={index}
                align={col.align ? col.align : 'left'}
                size={size ? size : 'medium'}
                className={cx(classes.tableCellClass, classes.tableHeadCellClass)}
                style={{width: col.width ? col.width : 'auto'}}
                sortDirection={orderDirection?.toLowerCase()}
              >
                {onTableColumnSort && col.sortKey ? (
                  <TableSortLabel
                    onClick={col.sortKey && onSortClick(col.sortKey)}
                    active={orderBy === col.sortKey}
                    direction={orderDirection?.toLowerCase()}
                  >
                    <CustomTooltip title={<Trans>Sort</Trans>}>{col.label}</CustomTooltip>
                  </TableSortLabel>
                ) : (
                  <span className={classes.tableLabel}>{col.label}</span>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <tr className={classes.tableLoadingRow}>
              <td colSpan={columns?.length}>
                <BoxFullWidth py={6}>
                  <Loader />
                </BoxFullWidth>
              </td>
            </tr>
          ) : data?.length ? (
            data?.map((row, rowIndex) => (
              <TableRow key={rowIndex} style={{cursor: 'pointer'}}>
                {columns?.map((col, colIndex) => (
                  <TableCell
                    onClick={onTableRowClick && rowClick(row)}
                    key={colIndex}
                    align={col.align ? col.align : 'left'}
                    scope="row"
                    size={size ? size : 'medium'}
                    style={{width: col.width ? col.width : 'auto'}}
                    className={cx(classes.tableCellClass, getCellClass && getCellClass(row))}
                  >
                    {col.render ? col.render(row[col.name], row, rowIndex) : row[col.name]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow key={'empty'}>
              <td colSpan={columns?.length} className={classes.emptyTable}>
                <div className={classes.emptyTableText}>
                  <Trans>No data</Trans>
                </div>
              </td>
            </TableRow>
          )}
          {pagination && (
            <tr>
              <td colSpan={columns?.length} className={classes.paginationCell}>
                <TablePagination
                  count={meta?.total_count}
                  page={page}
                  onChangePage={onChangePage}
                  meta={meta}
                  customRowsPerPageOptions={customRowsPerPageOptions}
                />
              </td>
            </tr>
          )}
        </TableBody>
      </Table>
    )
  )
}

CustomTable.propTypes = {
  classes: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.array,
  size: PropTypes.string,
  onTableRowClick: PropTypes.func,
  onTableColumnSort: PropTypes.func,
  tableSort: PropTypes.object,
  loading: PropTypes.bool,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  pagination: PropTypes.bool,
}

export default withStyles(materialStyle)(CustomTable)
