import GridItem from 'component/material/GridItem'
import Typography from '@material-ui/core/Typography'
import {Trans} from '@lingui/macro'
import CallDetailPreferDates from 'component/CallPage/CallDetailPreferDates'
import {Field} from 'react-final-form'
import CheckBoxInput from 'component/field/CheckboxInput'
import SelectInput from 'component/field/SelectInput'
import TextInput from 'component/field/TextInput'
import React from 'react'
import {useParams} from 'react-router-dom'

const AdditionalInformation = (props) => {
  const {
    callStatusData,
    initialValues,
    notEditableOrSubmitting,
    formProps,
    datesData,
    setDatesData,
    callStatusLoading,
    classes,
  } = props

  let {id} = useParams()

  return (
    <>
      <GridItem container xs={12} justifyContent={'center'}>
        <Typography variant={'h6'} style={{fontWeight: 600, marginBottom: 10, marginTop: 20}}>
          <Trans>Additional information</Trans>
        </Typography>
      </GridItem>

      <GridItem container xs={12} style={{marginTop: 20, marginBottom: 20}}>
        <CallDetailPreferDates
          disabled={notEditableOrSubmitting}
          classes={classes}
          formProps={formProps}
          data={datesData}
          setData={setDatesData}
        />
      </GridItem>

      <GridItem container xs={12}>
        <Field
          disabled={notEditableOrSubmitting}
          name="pick_up"
          label={<Trans>Pick up service</Trans>}
          component={CheckBoxInput}
          type={'checkbox'}
        />
      </GridItem>
      <GridItem container xs={12}>
        <Field
          disabled={notEditableOrSubmitting}
          name="courtesy_vehicle"
          label={<Trans>Courtesy vehicle</Trans>}
          component={CheckBoxInput}
          type={'checkbox'}
        />
      </GridItem>
      <GridItem container xs={12}>
        <Field
          disabled={formProps.submitting || id === 'new'}
          name="status_id"
          label={<Trans>Call status</Trans>}
          component={SelectInput}
          options={callStatusData?.map((s) => ({
            name: s.name,
            value: s.id,
          }))}
          loading={callStatusLoading}
        />
      </GridItem>
      <GridItem container xs={12}>
        <Field
          disabled={notEditableOrSubmitting}
          name="call"
          defaultValue={true}
          label={<Trans>Call</Trans>}
          component={CheckBoxInput}
          type={'checkbox'}
        />
      </GridItem>
      <GridItem container xs={12}>
        <Field
          disabled={notEditableOrSubmitting}
          name="notification_number"
          label={<Trans>Notification number</Trans>}
          component={TextInput}
        />
      </GridItem>
      <GridItem container xs={12} justifyContent={'flex-end'}>
        <GridItem xs={10}>
          <Field
            disabled={initialValues.status_id === 2 ? false : notEditableOrSubmitting}
            name="note"
            label={<Trans>Note</Trans>}
            component={TextInput}
            multiline
            minRows={4}
            filters={true}
            longLabel={true}
          />
        </GridItem>
      </GridItem>
    </>
  )
}

export default AdditionalInformation
