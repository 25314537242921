import {
  GET_CALL_FAIL,
  GET_CALL_LIST_FAIL,
  GET_CALL_LIST_REQUEST,
  GET_CALL_LIST_SUCCESS,
  GET_CALL_REQUEST,
  GET_CALL_SUCCESS,
  GET_CAR_HIS_FAIL,
  GET_CAR_HIS_REQUEST,
  GET_CAR_HIS_SUCCESS,
  GET_CHANGE_FAIL,
  GET_CHANGE_REQUEST,
  GET_CHANGE_SUCCESS,
  GET_FLEETS_FAIL,
  GET_FLEETS_REQUEST,
  GET_FLEETS_SUCCESS,
  GET_MARKS_FAIL,
  GET_MARKS_REQUEST,
  GET_MARKS_SUCCESS,
  GET_PHONE_HIS_FAIL,
  GET_PHONE_HIS_REQUEST,
  GET_PHONE_HIS_SUCCESS,
  GET_STATUS_LIST_FAIL,
  GET_STATUS_LIST_REQUEST,
  GET_STATUS_LIST_SUCCESS,
  POST_CAR_SEARCH_FAIL,
  POST_CAR_SEARCH_REQUEST,
  POST_CAR_SEARCH_SUCCESS,
} from 'redux/actionType'

const initState = {
  callListLoading: false,
  callListData: [],
  callListMeta: {},

  callStatusLoading: false,
  callStatusData: [],
  callStatusMeta: {},

  carData: {},
  carLoading: false,

  callDetailLoading: false,
  callDetail: {},

  marksListData: [],
  marksListLoading: false,
  marksListMeta: {},

  fleetsListData: [],
  fleetsListLoading: false,
  fleetsListMeta: {},

  phoneHistory: [],
  phoneHistoryLoading: false,
  phoneHistoryMeta: {},

  carHistory: [],
  carHistoryLoading: false,
  carHistoryMeta: {},

  changeLog: [],
  changeLogLoading: false,
  changeLogMeta: {},
}

const callReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_CALL_LIST_REQUEST: {
      return {
        ...state,
        callListLoading: true,
      }
    }
    case GET_CALL_LIST_SUCCESS: {
      return {
        ...state,
        callListLoading: false,
        callListData: action.data.objects,
        callListMeta: action.data.meta,
      }
    }
    case GET_CALL_LIST_FAIL: {
      return {
        ...state,
        callListLoading: false,
      }
    }
    case GET_STATUS_LIST_REQUEST: {
      return {
        ...state,
        callStatusLoading: true,
      }
    }
    case GET_STATUS_LIST_SUCCESS: {
      return {
        ...state,
        callStatusLoading: false,
        callStatusData: action.data.objects,
        callStatusMeta: action.data.meta,
      }
    }
    case GET_STATUS_LIST_FAIL: {
      return {
        ...state,
        callStatusLoading: false,
      }
    }
    case POST_CAR_SEARCH_REQUEST: {
      return {
        ...state,
        carLoading: true,
      }
    }
    case POST_CAR_SEARCH_SUCCESS: {
      return {
        ...state,
        carLoading: false,
        carData: action.data,
      }
    }
    case POST_CAR_SEARCH_FAIL: {
      return {
        ...state,
        carLoading: false,
      }
    }
    case GET_CALL_REQUEST: {
      return {
        ...state,
        callDetailLoading: true,
      }
    }
    case GET_CALL_SUCCESS: {
      return {
        ...state,
        callDetailLoading: false,
        callDetail: action.data,
      }
    }
    case GET_CALL_FAIL: {
      return {
        ...state,
        callDetailLoading: false,
      }
    }
    case GET_FLEETS_REQUEST: {
      return {
        ...state,
        fleetsListLoading: true,
      }
    }
    case GET_FLEETS_SUCCESS: {
      return {
        ...state,
        fleetsListLoading: false,
        fleetsListData: action.data.objects,
        fleetsListMeta: action.data.meta,
      }
    }
    case GET_FLEETS_FAIL: {
      return {
        ...state,
        fleetsListData: false,
      }
    }
    case GET_MARKS_REQUEST: {
      return {
        ...state,
        marksListLoading: true,
      }
    }
    case GET_MARKS_SUCCESS: {
      return {
        ...state,
        marksListLoading: false,
        marksListMeta: action.data.meta,
        marksListData: action.data.objects,
      }
    }
    case GET_MARKS_FAIL: {
      return {
        ...state,
        marksListLoading: false,
      }
    }
    case GET_PHONE_HIS_REQUEST: {
      return {
        ...state,
        phoneHistoryLoading: true,
      }
    }
    case GET_PHONE_HIS_SUCCESS: {
      return {
        ...state,
        phoneHistoryLoading: false,
        phoneHistory: action.data.objects,
        phoneHistoryMeta: action.data.meta,
      }
    }
    case GET_PHONE_HIS_FAIL: {
      return {
        ...state,
        phoneHistoryLoading: false,
      }
    }
    case GET_CAR_HIS_REQUEST: {
      return {
        ...state,
        carHistoryLoading: true,
      }
    }
    case GET_CAR_HIS_SUCCESS: {
      return {
        ...state,
        carHistoryLoading: false,
        carHistory: action.data.objects,
        carHistoryMeta: action.data.meta,
      }
    }
    case GET_CAR_HIS_FAIL: {
      return {
        ...state,
        carHistoryLoading: false,
      }
    }
    case GET_CHANGE_REQUEST: {
      return {
        ...state,
        changeLogLoading: true,
      }
    }
    case GET_CHANGE_SUCCESS: {
      return {
        ...state,
        changeLogLoading: false,
        changeLog: action.data.objects,
        changeLogMeta: action.data.meta,
      }
    }
    case GET_CHANGE_FAIL: {
      return {
        ...state,
        changeLogLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default callReducer
