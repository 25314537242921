import React, {useEffect, useState} from 'react'
import {Step, StepButton, StepLabel, Stepper} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import BoxFullWidth from 'component/material/BoxFullWidth'
import withStyles from '@material-ui/core/styles/withStyles'
import detailPageStyle from 'component/detailPageStyle'
import GridContainer from 'component/material/GridContainer'
import EstablishmentDetail from 'component/CallPage/EstablishmentDetail'
import GeneralDetail from 'component/CallPage/GeneralDetail'
import {fireInfoToast, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {cancelCall, getCall, resetCallData, resetCarFields} from 'redux/action/callAction'
import {connect} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import Typography from '@material-ui/core/Typography'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import copy from 'copy-to-clipboard'
import WarningDialog from 'component/material/WarningDialog'

const CallDetail = (props) => {
  const {
    classes,
    getCall,
    callDetail,
    callDetailLoading,
    resetCarFields,
    resetCallData,
    carData,
    cancelCall,
  } = props

  const [activeStep, setActiveStep] = useState(0)
  const [openCancelDialog, setOpenCancelDialog] = useState(false)

  let {id} = useParams()

  const steps = [<Trans>Call</Trans>, <Trans>Supplier</Trans>]

  const generalInitialValues = () => {
    if (id === 'new') {
      return {
        status_id: 3,
      }
    } else {
      return {
        ...callDetail,
        ...callDetail?.car,
        mark: callDetail?.car?.mark_name,
        fleet: callDetail?.car?.fleet_id,
      }
    }
  }

  const establishmentInitialValues = () => {
    return {
      ...callDetail,
    }
  }

  const renderContent = (step) => {
    const isNotEditable = callDetail?.is_editable === undefined ? false : !callDetail?.is_editable

    if (step === 0) {
      return (
        <GeneralDetail
          initialValues={generalInitialValues()}
          preferredTerms={callDetail?.preferred_terms}
          setActiveStep={setActiveStep}
          isNotEditable={isNotEditable}
        />
      )
    } else {
      return (
        <EstablishmentDetail
          initialValues={establishmentInitialValues()}
          preferredTerms={callDetail?.drop_off_terms}
          setActiveStep={setActiveStep}
          isNotEditable={isNotEditable}
        />
      )
    }
  }

  const loadingBoxStyle = {
    width: 'calc(100% + 8px)',
    marginLeft: '-4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 1,
    backdropFilter: 'blur(2px)',
  }

  const handleResetForm = () => {
    setActiveStep(0)
    redirectTo('/call/new')
    window.location.reload()
  }

  const handleCancelCall = () => {
    cancelCall(id)
      .then(() => redirectTo('/'))
      .catch((err) => {
        return mapErrorResponseToForm(err)
      })
  }

  useEffect(() => {
    if (id !== 'new') getCall(id)

    return () => {
      resetCarFields()
      resetCallData()
    }
  }, [])

  return (
    <>
      <GridContainer
        justifyContent={'space-between'}
        alignItems={'center'}
        style={{paddingBottom: 10}}
      >
        <GridItem xs={true}>
          <span onClick={() => redirectTo('/')} className={classes.backLinkText}>
            <Trans>{'<-back to list'}</Trans>
          </span>
        </GridItem>
        <GridItem xs={false}>
          {id !== 'new' && (
            <GridContainer spacing={5}>
              <GridItem>
                <PrimaryButton
                  className={classes.newCallButton}
                  onClick={handleResetForm}
                  size={'small'}
                  textPadding={0}
                  text={<Trans>New call</Trans>}
                />
              </GridItem>
              <GridItem>
                <PrimaryButton
                  className={classes.cancelCallButton}
                  onClick={() => setOpenCancelDialog(true)}
                  size={'small'}
                  textPadding={0}
                  text={<Trans>Cancel call</Trans>}
                />
              </GridItem>
            </GridContainer>
          )}

          <WarningDialog
            open={openCancelDialog}
            message={<Trans>This action is irreversible!</Trans>}
            title={<Trans>Are you sure he wants to cancel the call?</Trans>}
            onClose={() => setOpenCancelDialog(false)}
            onConfirm={handleCancelCall}
          />
        </GridItem>
      </GridContainer>

      <div className={classes.detailPageContainer} style={{position: 'relative'}}>
        <GridContainer justifyContent={'space-between'}>
          <GridItem xs={false}>
            {id !== 'new' && (
              <>
                <GridItem xs={12}>
                  {callDetail.operator_name && (
                    <Typography
                      onClick={() => {
                        copy(callDetail.operator_name)
                        fireInfoToast(
                          <Trans>Operator name copied: {callDetail.operator_name}</Trans>
                        )
                      }}
                      style={{
                        fontSize: 12,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      color={'textSecondary'}
                    >
                      <span style={{fontWeight: 600, paddingRight: 10}}>
                        <Trans>Operator</Trans>:
                      </span>
                      {callDetail.operator_name}
                      <FileCopyIcon style={{fontSize: '12px', paddingLeft: 5}} />
                    </Typography>
                  )}
                </GridItem>
                <GridItem xs={12}>
                  <Typography
                    onClick={() => {
                      copy(id)
                      fireInfoToast(<Trans>Call id copied: {id}</Trans>)
                    }}
                    style={{fontSize: 12, display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                    color={'textSecondary'}
                  >
                    <span style={{fontWeight: 600, paddingRight: 10}}>
                      <Trans>Call id</Trans>:
                    </span>
                    {id}
                    <FileCopyIcon style={{fontSize: '12px', paddingLeft: 5}} />
                  </Typography>
                </GridItem>
              </>
            )}
          </GridItem>
          <GridItem xs={'auto'}>
            <PrimaryButton
              fullWidth={false}
              textPadding={0}
              text={<Trans>Save</Trans>}
              size={'small'}
              onClick={() => document.querySelector('#save-button')?.click()}
            />
          </GridItem>
        </GridContainer>
        <BoxFullWidth>
          <Stepper
            nonLinear
            activeStep={activeStep}
            alternativeLabel
            style={{backgroundColor: 'transparent', marginBottom: 20}}
          >
            {steps.map((label, index) => {
              const hasCar = carData?.id || callDetail?.car?.id
              return (
                <Step key={index}>
                  <StepButton style={{cursor: 'default'}} disableRipple={true}>
                    <StepLabel
                      style={{cursor: 'pointer'}}
                      disabled={!hasCar}
                      onClick={() => {
                        if (index === 1) {
                          document.getElementById('continue-to-supplier')?.click()
                        } else {
                          document.getElementById('save-button')?.click()
                          setActiveStep(index)
                        }
                      }}
                    >
                      {label}
                    </StepLabel>
                  </StepButton>
                </Step>
              )
            })}
          </Stepper>
          <GridContainer>{renderContent(activeStep)}</GridContainer>
        </BoxFullWidth>

        {callDetailLoading && (
          <Box style={loadingBoxStyle}>
            <CircularProgress />
          </Box>
        )}
      </div>

      <BoxFullWidth>
        <span onClick={() => redirectTo('/')} className={classes.backLinkText}>
          <Trans>{'<-back to list'}</Trans>
        </span>
      </BoxFullWidth>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCall,
      resetCarFields,
      resetCallData,
      cancelCall,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      carData: store.call.carData,
      callDetail: store.call.callDetail,
      callDetailLoading: store.call.callDetailLoading,
    }
  }, mapDispatchToProps)
)(CallDetail)
