import React, {useCallback, useState} from 'react'
import GridItem from 'component/material/GridItem'
import Typography from '@material-ui/core/Typography'
import {Trans} from '@lingui/macro'
import {Field} from 'react-final-form'
import TextInput from 'component/field/TextInput'
import {validateRz} from 'helper/validations'
import PhoneInput from 'component/field/PhoneInput'
import {List, ListItem, Popover} from '@material-ui/core'
import GridContainer from 'component/material/GridContainer'

let phoneDebounceInterval = null

const GeneralInformation = (props) => {
  const {
    getDriverInfo,
    formProps,
    notEditableOrSubmitting,
    isNotNew,
    isCarFoundByVIN,
    carSearchLoading,
    rzOrVinDebounce,
  } = props

  const [phoneData, setPhoneData] = useState([])
  const [phoneLoading, setPhoneLoading] = useState(false)

  const handlePhoneChange = (formProps) => (value, data, event) => {
    const valueLength = value?.length
    const dialCodeLength = data?.dialCode?.length
    const countryCode = data?.countryCode

    handleClose()

    if (countryCode === 'cz') {
      if (valueLength === 12) {
        phoneDebounce(`+${value}`, formProps, event)
      } else if (valueLength === 16) {
        phoneDebounce(value.replaceAll(/\s/g, ''), formProps, event)
      }
    } else if (valueLength - dialCodeLength >= 7) {
      phoneDebounce(`+${value}`, formProps, event)
    }
  }

  const onSelectPhone = (phoneData, formProps) => {
    phoneData?.driver_company && formProps.form.change('driver_company', phoneData?.driver_company)
    phoneData?.driver_name && formProps.form.change('driver_name', phoneData?.driver_name)
    handleClose()
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const phoneDebounce = useCallback((value, formProps, event) => {
    setPhoneLoading(true)

    clearTimeout(phoneDebounceInterval)
    phoneDebounceInterval = setTimeout(() => {
      setPhoneData([])
      getDriverInfo(value)
        .then((res) => {
          if (res.length === 1) {
            if (res[0]?.driver_company) {
              formProps.form.change('driver_company', res[0]?.driver_company)
            }
            if (res[0]?.driver_name) {
              formProps.form.change('driver_name', res[0]?.driver_name)
            }
          } else {
            setPhoneData(res)
          }
        })
        .finally(() => {
          setAnchorEl(event.target.offsetParent)
          setPhoneLoading(false)
        })
    }, 500)
  }, [])

  return (
    <>
      <GridItem container xs={12} justifyContent={'center'}>
        <Typography variant={'h6'} style={{fontWeight: 600, marginBottom: 10}}>
          <Trans>General information</Trans>
        </Typography>
      </GridItem>
      <GridItem container xs={12}>
        <Field
          disabled={notEditableOrSubmitting || isNotNew || carSearchLoading || isCarFoundByVIN}
          name="registration_plate"
          onChange={(e) => rzOrVinDebounce(e, formProps)}
          label={<Trans>Registration plate</Trans>}
          component={TextInput}
          validate={validateRz}
          inputProps={{style: {textTransform: 'uppercase'}}}
          hasSearch={true}
          loading={carSearchLoading}
        />
      </GridItem>
      <GridItem container xs={12}>
        <Field
          disabled={notEditableOrSubmitting}
          name="odometer"
          label={<Trans>Odometer</Trans>}
          component={TextInput}
          type={'number'}
          inputProps={{min: 0, step: 1}}
        />
      </GridItem>
      <GridItem container xs={12} id={'phone-id'}>
        <Field
          disabled={notEditableOrSubmitting}
          name="phone"
          label={<Trans>Driver phone number</Trans>}
          component={PhoneInput}
          onChange={handlePhoneChange(formProps)}
          onFocus={handleOpen}
        />
        {!phoneLoading && phoneData?.length >= 1 && (
          <Popover
            id={'phone-field'}
            disableAutoFocus={true}
            disableEnforceFocus={true}
            disablePortal={true}
            disableRestoreFocus={true}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <List
              dense={true}
              disablePadding={true}
              style={{width: document.getElementById('phone-id')?.clientWidth - 260}}
            >
              {phoneData.map((p, i) => (
                <ListItem
                  key={i}
                  button
                  disableGutters={true}
                  onClick={() => onSelectPhone(p, formProps)}
                >
                  <GridContainer style={{paddingLeft: 8, paddingRight: 8}}>
                    <GridItem xs={12}>
                      <Typography style={{fontSize: 12}}>{p.driver_name}</Typography>
                    </GridItem>
                    <GridItem xs={12}>
                      <Typography style={{fontSize: 11}}>{p.driver_company}</Typography>
                    </GridItem>
                  </GridContainer>
                </ListItem>
              ))}
            </List>
          </Popover>
        )}
      </GridItem>
      <GridItem container xs={12}>
        <Field
          disabled={notEditableOrSubmitting}
          name="driver_name"
          label={<Trans>Driver name</Trans>}
          component={TextInput}
        />
      </GridItem>

      <GridItem container xs={12}>
        <Field
          disabled={notEditableOrSubmitting}
          name="driver_company"
          label={<Trans>Driver company</Trans>}
          component={TextInput}
        />
      </GridItem>
    </>
  )
}

export default GeneralInformation
