// auth action types
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

// call
export const GET_CALL_LIST_REQUEST = 'GET_CALL_LIST_REQUEST'
export const GET_CALL_LIST_SUCCESS = 'GET_CALL_LIST_SUCCESS'
export const GET_CALL_LIST_FAIL = 'GET_CALL_LIST_FAIL'

export const GET_CALL_REQUEST = 'GET_CALL_REQUEST'
export const GET_CALL_SUCCESS = 'GET_CALL_SUCCESS'
export const GET_CALL_FAIL = 'GET_CALL_FAIL'

export const GET_FLEETS_REQUEST = 'GET_FLEETS_REQUEST'
export const GET_FLEETS_SUCCESS = 'GET_FLEETS_SUCCESS'
export const GET_FLEETS_FAIL = 'GET_FLEETS_FAIL'

export const GET_MARKS_REQUEST = 'GET_MARKS_REQUEST'
export const GET_MARKS_SUCCESS = 'GET_MARKS_SUCCESS'
export const GET_MARKS_FAIL = 'GET_MARKS_FAIL'

export const GET_PHONE_HIS_REQUEST = 'GET_PHONE_HIS_REQUEST'
export const GET_PHONE_HIS_SUCCESS = 'GET_PHONE_HIS_SUCCESS'
export const GET_PHONE_HIS_FAIL = 'GET_PHONE_HIS_FAIL'

export const GET_CAR_HIS_REQUEST = 'GET_CAR_HIS_REQUEST'
export const GET_CAR_HIS_SUCCESS = 'GET_CAR_HIS_SUCCESS'
export const GET_CAR_HIS_FAIL = 'GET_CAR_HIS_FAIL'

export const GET_CHANGE_REQUEST = 'GET_CHANGE_REQUEST'
export const GET_CHANGE_SUCCESS = 'GET_CHANGE_SUCCESS'
export const GET_CHANGE_FAIL = 'GET_CHANGE_FAIL'

// statuses
export const GET_STATUS_LIST_REQUEST = 'GET_STATUS_LIST_REQUEST'
export const GET_STATUS_LIST_SUCCESS = 'GET_STATUS_LIST_SUCCESS'
export const GET_STATUS_LIST_FAIL = 'GET_STATUS_LIST_FAIL'

// car search
export const POST_CAR_SEARCH_REQUEST = 'POST_CAR_SEARCH_REQUEST'
export const POST_CAR_SEARCH_SUCCESS = 'POST_CAR_SEARCH_SUCCESS'
export const POST_CAR_SEARCH_FAIL = 'POST_CAR_SEARCH_FAIL'

export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL'
export const PUT_CURRENT_USER_REQUEST = 'PUT_CURRENT_USER_REQUEST'
export const PUT_CURRENT_USER_SUCCESS = 'PUT_CURRENT_USER_SUCCESS'
export const PUT_CURRENT_USER_FAIL = 'PUT_CURRENT_USER_FAIL'
export const PUT_CHANGE_PASSWORD_REQUEST = 'PUT_CHANGE_PASSWORD_REQUEST'
export const PUT_CHANGE_PASSWORD_SUCCESS = 'PUT_CHANGE_PASSWORD_SUCCESS'
export const PUT_CHANGE_PASSWORD_FAIL = 'PUT_CHANGE_PASSWORD_FAIL'

export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS'
