import React, {useEffect, useState} from 'react'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import {Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import detailPageStyle from 'component/detailPageStyle'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PrimaryButton from 'component/material/PrimaryButton'
import moment from 'moment'
import {
  fireErrorToast,
  fireSuccessToast,
  mapErrorResponseToForm,
  redirectTo,
} from 'helper/functions'
import CarCard from 'component/CallPage/CarCard'
import {DATE_TIME_DISPLAY_FORMAT} from 'helper/constants'
import {useParams} from 'react-router-dom'
import Box from '@material-ui/core/Box'
import {diff} from 'deep-object-diff'
import {
  carSearch,
  createCall,
  getCall,
  getChangeLog,
  getDriverInfo,
  getStatusList,
  patchCall,
} from 'redux/action/callAction'
import CallChangeLog from 'component/CallPage/CallChangeLog'
import GeneralInformation from 'component/CallPage/GeneralDetail/GeneralInformation'
import ScopeOfRepair from 'component/CallPage/GeneralDetail/ScopeOfRepair'
import AdditionalInformation from 'component/CallPage/GeneralDetail/AdditionalInformation'

let debounceInterval = null

const GeneralDetail = (props) => {
  const {
    createCall,
    carSearch,
    getStatusList,
    classes,
    initialValues,
    carData,
    carLoading,
    preferredTerms,
    patchCall,
    setActiveStep,
    getDriverInfo,
    isNotEditable,
    getCall,
    getChangeLog,
    callStatusData,
    callStatusLoading,
  } = props

  const [savedFormProps, setSavedFormProps] = useState(null)
  const [datesData, setDatesData] = useState([])
  const [vinNotFound, setVinNotFound] = useState(false)
  const [carSearchLoading, setCarSearchLoading] = useState(false)
  const [isCarFoundByVIN, setIsCarFoundByVIN] = useState(false)
  const [formValues, setFormValues] = useState(null)

  let {id} = useParams()
  const isNotNew = id !== 'new'

  const prepareData = (values) => {
    let data = {
      service_visit: !!values.service_visit,
      tire_service: !!values.tire_service,
      brakes: !!values.brakes,
      lighting: !!values.lighting,
      undercarriage: !!values.undercarriage,
      glasses: !!values.glasses,
      electro: !!values.electro,
      pick_up: !!values.pick_up,
      stk: !!values.stk,
      courtesy_vehicle: !!values.courtesy_vehicle,
      call: !!values.call,
      odometer: values.odometer,
      phone: values.phone && `+${values.phone}`,
      driver_name: values.driver_name,
      driver_company: values.driver_company,
      scope_of_repair: values.scope_of_repair,
      notification_number: values.notification_number,
      note: values.note,
      preferred_terms: datesData.map((d) => ({
        priority: d.priority,
        datetime: moment(d.datetime, DATE_TIME_DISPLAY_FORMAT).utc().format(),
      })),
      car_id: carData?.id || values.car_id,
      registration_plate: values.registration_plate,
    }

    if (isNotNew) data.status_id = values.status_id

    return data
  }

  const handleCreateCallAndContinue = (values) => {
    if (isNotNew) {
      handleEditCall(values, true)
      setActiveStep(1)
    } else {
      const data = prepareData(values)
      return createCall(data)
        .then((res) => {
          redirectTo(`/call/${res.id}`)
          getCall(res.id)
          setActiveStep(1)
        })
        .catch((err) => {
          const errors = mapErrorResponseToForm(err)
          if (errors?.car_id === 'This field is required.') {
            fireErrorToast(<Trans>No assigned car.</Trans>)
          }
          return errors
        })
    }
  }

  const handleEditCall = (values, isNew = false) => {
    if (isNotNew) {
      let diffData = diff(initialValues, values)

      delete diffData?.preferredDate

      Object.entries(diffData).forEach(([key, value]) => {
        if (value === undefined) {
          diffData[key] = null
        }
      })

      return patchCall(id, {
        ...diffData,
        phone: diffData?.phone && `+${diffData.phone}`,
        preferred_terms: datesData.map((d) => ({
          priority: d.priority,
          datetime: moment(d.datetime, DATE_TIME_DISPLAY_FORMAT).utc().format(),
        })),
      })
        .then((res) => {
          if (!isNew && initialValues.status_id !== 2 && values.status_id === 2) {
            redirectTo(`/`)
          }
          getChangeLog(res.id)
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      const data = prepareData(values)
      return createCall(data)
        .then((res) => {
          redirectTo(`/call/${res.id}`)
          getChangeLog(res.id)
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const handleResetCarFields = async (e, formProps) => {
    const name = e.target.name
    const value = e.target.value

    const currentValuesToSave = {}

    const fields = [
      'product_code',
      'warranty_date',
      'renter',
      'mark',
      'fleet',
      'model',
      'year_of_manufacture',
      'fuel',
      'power',
      'engine_capacity',
    ]

    for (const property in formProps.values) {
      if (![...fields].includes(property)) {
        currentValuesToSave[property] = formProps.values?.[property]
      }
    }

    setFormValues({...currentValuesToSave, [name]: value})

    fields.forEach((key) => {
      formProps.form.change(key, undefined)
    })
  }

  const setResponseValuesToForm = (data, form) => {
    form.change('vin', data.vin)
    form.change('mark', data.mark_name)
    form.change('model', data.model)
    form.change('year_of_manufacture', data.year_of_manufacture)
    form.change('renter', data.renter)
    form.change('product_code', data.product_code)
    form.change('premium', data.premium)
    form.change('warranty_date', data.warranty_date)
    form.change('fuel', data.fuel)
    form.change('power', data.power)
    form.change('engine_capacity', data.engine_capacity)
  }

  const rzOrVinDebounce = (e, formProps) => {
    handleResetCarFields(e, formProps).then(() => {
      clearTimeout(debounceInterval)
      debounceInterval = setTimeout(() => {
        const name = e.target.name
        const value = e.target.value?.toUpperCase()
        if (
          (name === 'registration_plate' && value.length === 7) ||
          (name === 'vin' && value.length === 17)
        ) {
          setVinNotFound(false)
          setCarSearchLoading(true)
          carSearch({type: name, query: value})
            .then((res) => {
              if (name === 'vin') {
                setIsCarFoundByVIN(true)
                if (!formProps.values?.registration_plate) {
                  formProps.form.change('registration_plate', res.registration_plate)
                }
              }
              res.fleet_name && formProps.form.change('fleet_name', res.fleet_name)
              fireSuccessToast(<Trans>Car was found</Trans>)
            })
            .catch((err) => {
              if (err.some((e) => e.status === 404 && e.message === 'Car not found.')) {
                setVinNotFound(true)
              }
            })
            .finally(() => {
              setCarSearchLoading(false)
            })
        }
      }, 250)
    })
  }

  const getInitVal = () => {
    return {...initialValues, ...formValues}
  }

  const notEditableOrSubmitting = (formProps) => isNotEditable || formProps.submitting

  useEffect(() => {
    if (preferredTerms) {
      setDatesData(
        preferredTerms.map((d) => ({
          priority: d.priority,
          datetime: moment(d.datetime).format(DATE_TIME_DISPLAY_FORMAT),
        }))
      )
    }
  }, [preferredTerms])

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    getStatusList()
  }, [])

  useEffect(() => {
    if (savedFormProps) {
      setResponseValuesToForm(carData, savedFormProps)
    }
  }, [carData])

  return (
    <BoxFullWidth>
      <Form onSubmit={handleCreateCallAndContinue} initialValues={getInitVal()}>
        {(formProps) => {
          if (!savedFormProps) {
            setSavedFormProps(formProps.form)
          }
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                {/*  LEFT SIDE  */}
                <GridContainer
                  item
                  xs={6}
                  style={{borderRight: '1px solid #bdbdbd', padding: '20px 40px 20px 20px'}}
                  alignContent={'flex-start'}
                >
                  <GeneralInformation
                    formProps={formProps}
                    notEditableOrSubmitting={notEditableOrSubmitting(formProps)}
                    isNotNew={isNotNew}
                    isCarFoundByVIN={isCarFoundByVIN}
                    carSearchLoading={carSearchLoading}
                    getDriverInfo={getDriverInfo}
                    rzOrVinDebounce={rzOrVinDebounce}
                  />

                  <ScopeOfRepair notEditableOrSubmitting={notEditableOrSubmitting(formProps)} />

                  <AdditionalInformation
                    notEditableOrSubmitting={notEditableOrSubmitting(formProps)}
                    formProps={formProps}
                    classes={classes}
                    datesData={datesData}
                    setDatesData={setDatesData}
                    initialValues={initialValues}
                    callStatusData={callStatusData}
                    callStatusLoading={callStatusLoading}
                  />
                </GridContainer>

                {/*  RIGHT SIDE  */}
                <CarCard
                  id={id}
                  loading={carLoading}
                  carData={carData}
                  vinNotFound={vinNotFound}
                  classes={classes}
                  formProps={formProps}
                  rzOrVinDebounce={rzOrVinDebounce}
                  carSearchLoading={carSearchLoading}
                  disabled={notEditableOrSubmitting(formProps)}
                />
              </GridContainer>

              {isNotNew && <CallChangeLog />}

              <GridContainer justifyContent={'flex-end'} style={{paddingTop: 40}}>
                <Box style={{paddingRight: 20}}>
                  <PrimaryButton
                    fullWidth={false}
                    textPadding={0}
                    text={<Trans>Save</Trans>}
                    size={'small'}
                    onClick={() => handleEditCall(formProps.values)}
                    disabled={formProps.submitting}
                    id={'save-button'}
                  />
                </Box>

                <PrimaryButton
                  fullWidth={false}
                  textPadding={0}
                  text={<Trans>Continue to suppliers</Trans>}
                  size={'small'}
                  type={'submit'}
                  id={'continue-to-supplier'}
                  disabled={formProps.submitting}
                />
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </BoxFullWidth>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStatusList,
      createCall,
      carSearch,
      patchCall,
      getDriverInfo,
      getCall,
      getChangeLog,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      callStatusLoading: store.call.callStatusLoading,
      callStatusData: store.call.callStatusData,
      callStatusMeta: store.call.callStatusMeta,
      carData: store.call.carData,
      carLoading: store.call.carLoading,
      changeLogLoading: store.call.changeLogLoading,
      changeLog: store.call.changeLog,
      changeLogMeta: store.call.changeLogMeta,
    }
  }, mapDispatchToProps)
)(GeneralDetail)
