import React, {useState} from 'react'
import {ReactComponent as LKQ} from 'style/asset/lkq.svg'
import Cfs from 'style/asset/cfs.png'
import {ReactComponent as Dots} from 'style/asset/menu-dots.svg'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import {Trans} from '@lingui/macro'
import {logout} from 'redux/action/authAction'
import ChangeMyPasswordDialog from 'layout/PrivateLayout/ChangeMyPasswordDialog'
import Loader from 'component/material/Loader'
import {redirectTo} from 'helper/functions'
import {Select} from '@material-ui/core'
import {updateGlobalSettings} from 'redux/action/globalSettingsAction'
import {LANGUAGE_LIST} from 'helper/constants'

const PrivateLayoutTopBar = (props) => {
  const {classes, currentUser, logout, loading, currentLanguage, updateGlobalSettings} = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [openChangePassword, setOpenChangePassword] = useState(false)

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true)
  }

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    return logout()
  }

  const handleChangeLanguage = (lang) => {
    updateGlobalSettings({currentLanguage: lang})
  }

  return (
    <>
      <GridContainer justifyContent={'space-between'} className={classes.topBar}>
        <GridItem xs={7} container direction={'row'}>
          <div className={classes.topBarLKQ} onClick={() => redirectTo('/')}>
            <LKQ />
          </div>
          <div className={classes.topBarElit}>
            <img src={Cfs} alt={'cfs'} />
          </div>
        </GridItem>
        <GridItem container xs={5} className={classes.userMenu} justifyContent={'flex-end'}>
          <GridItem className={classes.userName}>
            {loading ? <Loader className={classes.nameLoader} /> : currentUser?.name}
          </GridItem>
          <GridItem className={classes.languageSelectContainer}>
            <Select
              value={currentLanguage}
              onChange={(e) => handleChangeLanguage(e.target.value)}
              className={classes.languageSelect}
              autoWidth={true}
            >
              {LANGUAGE_LIST.map((country, index) => (
                <MenuItem value={country.value} key={index} className={classes.languageSelectItem}>
                  {country.icon}
                </MenuItem>
              ))}
            </Select>
          </GridItem>
          <GridItem className={classes.svgDots} onClick={handleClick}>
            <Dots />
          </GridItem>
        </GridItem>
      </GridContainer>

      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.dropDownMenu}
      >
        <MenuItem className={classes.dropDownMenuItem} onClick={handleOpenChangePassword}>
          <VpnKeyOutlinedIcon />
          <Trans>Change password</Trans>
        </MenuItem>
        <MenuItem className={classes.dropDownMenuItem} onClick={handleLogout}>
          <ExitToAppIcon />
          <Trans>Logout</Trans>
        </MenuItem>
      </Menu>

      <ChangeMyPasswordDialog
        onClose={handleCloseChangePassword}
        handleCloseMenu={handleClose}
        open={openChangePassword}
      />
    </>
  )
}

PrivateLayoutTopBar.propTypes = {
  classes: PropTypes.object,
  currentUser: PropTypes.object,
  logout: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
      updateGlobalSettings,
    },
    dispatch
  )
}

export default compose(
  withStyles(privateLayoutStyle),
  connect((store) => {
    return {
      currentLanguage: store.globalSettings.currentLanguage,
    }
  }, mapDispatchToProps)
)(PrivateLayoutTopBar)
