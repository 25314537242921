import {createTheme} from '@material-ui/core/styles'

const theme = createTheme({
  typography: {
    // htmlFontSize sets size of 1rem for app
    htmlFontSize: 12,
    fontSize: 12,
    fontFamily: ['Inter', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontFamily: "'Inter','Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 300,
      fontSize: '5rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: "'Inter','Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: "'Inter','Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: "'Inter','Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: "'Inter','Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: "'Inter','Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    body1: {
      fontFamily: "'Inter','Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: "'Inter','Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: "'Inter','Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
  },
  palette: {
    primary: {
      light: '#336f99',
      main: '#004b80',
      dark: '#003459',
      contrastText: '#fafafa',
    },
    secondary: {
      light: '#33a2ff',
      main: '#018bff',
      dark: '#0061b2',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
      light: '#81c784',
      main: '#008028',
      dark: '#016e21',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff5f04',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      light: '#e57373',
      main: '#ce2139',
      dark: '#a2081d',
      contrastText: '#fff',
    },
    text: {
      primary: '#000000',
      secondary: '#585b6c',
      disabled: '#babdcc',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    border: 'rgba(0, 0, 0, 0.23)',
    lightBorder: '#dfdfe9',
    background: {
      paper: '#f6f8fa',
      default: '#f0f0f5',
      white: '#ffffff',
      elitDarkGray: 'rgb(224,224,224)',
      elitGray: 'rgb(247,247,247)',
    },
  },
  // border radius of input fields and buttons
  shape: {
    borderRadius: 4,
  },
  // spacing sets size of padding and margin
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

export default theme
