import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import {Field} from 'react-final-form'
import PrimaryButton from 'component/material/PrimaryButton'
import {ReactComponent as Bin} from 'style/asset/bin.svg'
import Box from '@material-ui/core/Box'
import moment from 'moment'
import {DATE_TIME_DISPLAY_FORMAT} from 'helper/constants'
import DatetimePickerInput from 'component/field/DatetimePickerInput'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

const CallDetailPreferDates = (props) => {
  const {formProps, data, setData, label = <Trans>Preferred dates</Trans>, classes} = props

  const handleAddDate = () => {
    const value = formProps.values?.preferredDate
    if (value) {
      const momentValue = moment(value, DATE_TIME_DISPLAY_FORMAT).format(DATE_TIME_DISPLAY_FORMAT)
      setData((prevState) => [
        ...prevState,
        {datetime: momentValue, priority: prevState.length + 1},
      ])
      formProps.form.change('preferredDate', null)
    }
  }

  const removeDate = (index) => () => {
    setData((prevState) => [
      ...prevState
        .filter((r, i) => i !== index)
        .map((r, i) => ({datetime: r.datetime, priority: i + 1})),
    ])
  }

  const increasePriority = (index) => {
    if (index !== 0) {
      const element = data.slice(index, index + 1)?.[0]
      const aboveElement = data.slice(index - 1, index)?.[0]

      const newData = [...data]

      newData.splice(index - 1, 1, {...element, priority: index})
      newData.splice(index, 1, {...aboveElement, priority: index + 1})

      setData(newData)
    }
  }

  const decreasePriority = (index) => {
    if (index !== data.length - 1) {
      const element = data.slice(index, index + 1)?.[0]
      const belowElement = data.slice(index + 1, index + 2)?.[0]

      const newData = [...data]

      newData.splice(index + 1, 1, {...element, priority: index + 2})
      newData.splice(index, 1, {...belowElement, priority: index + 1})

      setData(newData)
    }
  }

  return (
    <GridContainer>
      <GridItem xs={10}>
        <Field
          disablePast={true}
          label={label}
          name="preferredDate"
          component={DatetimePickerInput}
          clearLabel={<Trans>Reset</Trans>}
          placeholder={'dd.mm.yyyy hh:mm'}
          clearable={true}
        />
      </GridItem>

      <GridItem container xs={2} style={{paddingLeft: 10}}>
        <PrimaryButton
          onClick={handleAddDate}
          size={'small'}
          textPadding={0}
          text={<Trans>Add</Trans>}
        />
      </GridItem>

      <GridItem container xs={12} justifyContent={'flex-end'} style={{fontSize: 12}}>
        <GridItem xs={10}>
          <GridContainer
            style={{
              borderBottom: '2px solid #bdbdbd',
              fontWeight: 600,
              marginBottom: 4,
              marginTop: 4,
              minHeight: 30,
            }}
            alignItems={'center'}
          >
            <GridItem xs={4} style={{padding: 4}}>
              <Trans>Priority</Trans>
            </GridItem>
            <GridItem xs={6} style={{padding: 4}}>
              <Trans>Datetime</Trans>
            </GridItem>
            <GridItem xs={2} style={{padding: 4}}>
              <Trans>Remove</Trans>
            </GridItem>
          </GridContainer>

          {data?.length ? (
            data?.map((record, index) => (
              <GridContainer
                key={index}
                style={{
                  minHeight: 30,
                  backgroundColor: index % 2 ? 'rgb(248,248,248)' : 'rgb(231,231,231)',
                }}
                alignItems={'center'}
              >
                <GridItem container xs={4} style={{padding: 4}} alignItems={'center'}>
                  <GridItem xs={2}>{record.priority}.</GridItem>
                  <GridItem container xs={true} style={{paddingLeft: 10}}>
                    <ArrowUpwardIcon
                      onClick={() => increasePriority(index)}
                      className={classes.predefinedTermsArrowIcon}
                    />
                    <ArrowDownwardIcon
                      onClick={() => decreasePriority(index)}
                      className={classes.predefinedTermsArrowIcon}
                      style={{paddingLeft: 5}}
                    />
                  </GridItem>
                </GridItem>
                <GridItem xs={6} style={{padding: 4}}>
                  {record.datetime}
                </GridItem>
                <GridItem container style={{padding: 4}} justifyContent={'flex-end'} xs={2}>
                  <Box
                    onClick={removeDate(index)}
                    style={{
                      marginRight: 4,
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <Bin />
                  </Box>
                </GridItem>
              </GridContainer>
            ))
          ) : (
            <GridContainer justifyContent={'center'}>
              <Box style={{color: '#bdbdbd', minHeight: 30, display: 'flex', alignItems: 'center'}}>
                <Trans>No pick up dates</Trans>
              </Box>
            </GridContainer>
          )}
        </GridItem>
      </GridItem>
    </GridContainer>
  )
}

export default CallDetailPreferDates
