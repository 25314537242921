import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import materialStyle from 'component/material/materialStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import {ReactComponent as Close} from 'style/asset/close.svg'
import DialogActions from '@material-ui/core/DialogActions'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'

const DialogWindow = (props) => {
  const {
    classes,
    children,
    dialogActions,
    open,
    onClose,
    title,
    fullWidth = true,
    maxWidth = 'lg',
    ...rest
  } = props

  return (
    <Dialog open={open} onClose={onClose} fullWidth={fullWidth} maxWidth={maxWidth} {...rest}>
      <GridContainer direction={'column'}>
        {title && (
          <GridItem
            container
            justifyContent={'center'}
            alignItems={'center'}
            xs={12}
            className={classes.dialogTitle}
          >
            {title}
          </GridItem>
        )}
        {onClose ? (
          <div className={classes.dialogCloseButton} onClick={onClose}>
            <Close />
          </div>
        ) : null}
        <DialogContent className={classes.dialogContent}>{children}</DialogContent>
        {dialogActions && (
          <DialogActions className={classes.dialogActions}>{dialogActions}</DialogActions>
        )}
      </GridContainer>
    </Dialog>
  )
}

DialogWindow.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  title: PropTypes.node,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
}

export default withStyles(materialStyle)(DialogWindow)
