import React from 'react'
import Warning from '@material-ui/icons/Warning'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import DialogWindow from 'component/material/DialogWindow'
import GridContainer from 'component/material/GridContainer'
import materialStyle from 'component/material/materialStyle'
import {Typography} from '@material-ui/core'
import Box from '@material-ui/core/Box'

const WarningDialog = (props) => {
  const {open, onClose, onConfirm, classes, message, title, maxWidth = 'xs'} = props

  return (
    <DialogWindow maxWidth={maxWidth} onClose={onClose} open={open}>
      <GridContainer justifyContent="center">
        <GridContainer justifyContent="center" direction={'row'}>
          <Box pt={4} pb={3} px={10} className={classes.warningDialogTitleIcon}>
            <Warning className={classes.warningDialogIcon} />
            <span className={classes.warningDialogTitle}>
              {title || <Trans>Are you sure?</Trans>}
            </span>
          </Box>
        </GridContainer>
        <Box pb={3}>
          {message && (
            <GridContainer justifyContent="center">
              <Typography color={'primary'} component={'span'} variant={'h6'}>
                {message}
              </Typography>
            </GridContainer>
          )}
        </Box>
        <GridContainer>
          <GridItem container xs={6} justifyContent="flex-end">
            <Box py={5} px={3}>
              <PrimaryButton
                fullWidth={false}
                textPadding={0}
                text={<Trans>No</Trans>}
                onClick={onClose}
                variant={'outlined'}
              />
            </Box>
          </GridItem>
          <GridItem xs={6}>
            <Box py={5} px={3}>
              <PrimaryButton
                fullWidth={false}
                textPadding={0}
                text={<Trans>Yes</Trans>}
                onClick={onConfirm}
              />
            </Box>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </DialogWindow>
  )
}

WarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
  message: PropTypes.node,
}

export default withStyles(materialStyle)(WarningDialog)
