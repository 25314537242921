import React from 'react'
import {Trans} from '@lingui/macro'
import {DATE_FORMAT} from 'helper/constants'
import moment from 'moment'

/// undefined == no error for the react-final-form ///

// connect multiple validations together,
// otherwise you can omit this function and use single validation on Field
export const composeValidators =
  (...validators) =>
  (value) => {
    return validators.reduce((error, validator) => error || validator(value), undefined)
  }

export const required = (value) => {
  return value ? undefined : <Trans>Required field</Trans>
}

export const validateEmail = (value) => {
  if (value && !value?.includes('@')) {
    return <Trans>Wrong email address</Trans>
  }
  return undefined
}

// form level validation of identity of passwords
export const samePasswordValidation = (values) => {
  if (values.new_password !== values.password_confirmation) {
    return {password_confirmation: <Trans>Passwords must be the same</Trans>}
  }
  return undefined
}

export const maxDate = (maxDate) => (value) => {
  if (moment(maxDate, DATE_FORMAT) < moment(value, DATE_FORMAT)) {
    return (
      <Trans>Date cannot be greater than {moment(maxDate, DATE_FORMAT).format(DATE_FORMAT)}</Trans>
    )
  }
  return undefined
}

export const minDate = (minDate) => (value) => {
  if (moment(minDate, DATE_FORMAT) > moment(value, DATE_FORMAT)) {
    return (
      <Trans>Date cannot be smaller than {moment(minDate, DATE_FORMAT).format(DATE_FORMAT)}</Trans>
    )
  }
  return undefined
}

export const validateVin = (value) => {
  const val = value?.toUpperCase()
  if (!!val) {
    if (/[A-Z0-9]/g.test(val)) {
      if (val.includes('I') || val.includes('O') || val.includes('Q')) {
        return <Trans>Value can not contain I,O or Q</Trans>
      } else if (val?.length !== 17) {
        return <Trans>VIN must be 17 characters long</Trans>
      }
    } else if (/[ ]/g.test(val)) {
      return <Trans>Empty space is not valid character</Trans>
    } else {
      return <Trans>Value is not valid VIN</Trans>
    }
  }
  return undefined
}

export const validateRz = (value) => {
  const val = value?.toUpperCase()
  if (!!val) {
    if (/[^A-Z0-9]/g.test(val)) {
      if (/[ ]/g.test(val)) {
        return <Trans>Empty space is not valid character</Trans>
      } else {
        return <Trans>Value is not valid RZ</Trans>
      }
    }
  }
  return undefined
}

export const maxLength = (max) => (value) => {
  return value?.length > max ? (
    <Trans>Number of characters can not be more than {max}</Trans>
  ) : undefined
}

export const minLength = (min) => (value) => {
  return value?.length < min ? (
    <Trans>Number of characters must be at least {min}</Trans>
  ) : undefined
}
