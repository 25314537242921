import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import GridContainer from 'component/material/GridContainer'
import {getCarHistory, getPhoneHistory} from 'redux/action/callAction'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import moment from 'moment/moment'
import {DATE_TIME_DISPLAY_FORMAT} from 'helper/constants'
import Table from 'component/material/table/Table'
import withStyles from '@material-ui/core/styles/withStyles'
import componentStyle from 'component/componentStyle'
import Typography from '@material-ui/core/Typography'

const EstablishmentHistory = (props) => {
  const {
    classes,
    getPhoneHistory,
    getCarHistory,
    phoneHistory,
    phoneHistoryLoading,
    phoneHistoryMeta,
    carHistory,
    carHistoryLoading,
    carHistoryMeta,
    selectSupplier,
  } = props

  let {id} = useParams()

  const [carTableSort, setCarTableSort] = useState({orderBy: 'id', orderDirection: 'DESC'})
  const [carPage, setCarPage] = useState(0)
  const [phoneTableSort, setPhoneTableSort] = useState({orderBy: 'id', orderDirection: 'DESC'})
  const [phonePage, setPhonePage] = useState(0)

  const handleChangeCarPage = (newPage) => {
    setCarPage(newPage)
  }

  const handleCarTableSort = (sort) => {
    setCarTableSort(sort)
  }

  const handleChangePhonePage = (newPage) => {
    setPhonePage(newPage)
  }

  const handlePhoneTableSort = (sort) => {
    setPhoneTableSort(sort)
  }

  const handleRowClick = (row) => {
    document.getElementById('supplier-item').scrollIntoView({
      behavior: 'smooth',
    })
    return row?.supplier_id && selectSupplier(row.supplier_id)
  }

  const columns = [
    {
      name: 'id',
      label: <Trans>ID</Trans>,
      render: (val) => (
        <>
          <span
            onClick={(e) => {
              e.stopPropagation()
              window.open(`/call/${val}`)
            }}
            className={classes.tableIdCell}
          />
          <span className={classes.tableIdLink}>{val}</span>
        </>
      ),
    },
    {
      name: 'driver_name',
      label: <Trans>Driver name</Trans>,
    },
    {
      name: 'phone',
      label: <Trans>Phone</Trans>,
    },
    {
      name: 'date_of_execution',
      label: <Trans>Date of execution</Trans>,
      render: (val) => val && moment(val).format(DATE_TIME_DISPLAY_FORMAT),
    },
    {
      name: 'supplier_name',
      label: <Trans>Supplier name</Trans>,
    },
    {
      name: 'supplier_address',
      label: <Trans>Supplier address</Trans>,
    },
  ]

  const getCellClass = (row) => {
    if (row?.supplier_is_usable) {
      return classes.cellGreenBackground
    } else {
      return classes.cellRedBackground
    }
  }

  useEffect(() => {
    if (id && id !== 'new') {
      getCarHistory(id, 10, carPage * 10, carTableSort)
    }
  }, [carPage, carTableSort])

  useEffect(() => {
    if (id && id !== 'new') {
      getPhoneHistory(id, 10, phonePage * 10, phoneTableSort)
    }
  }, [phonePage, phoneTableSort])

  return (
    <GridContainer item xs={12}>
      <GridItem xs={12} container justifyContent={'center'}>
        <Typography variant={'h6'} style={{fontWeight: 600, marginBottom: 16}}>
          <Trans>Car history</Trans>
        </Typography>
      </GridItem>

      <GridItem xs={12} style={{paddingBottom: 40}}>
        <div className={classes.overFlowAuto}>
          <Table
            data={carHistory}
            columns={columns}
            loading={carHistoryLoading}
            onTableColumnSort={handleCarTableSort}
            onTableRowClick={handleRowClick}
            tableSort={carTableSort}
            page={carPage}
            meta={carHistoryMeta}
            onChangePage={handleChangeCarPage}
            getCellClass={getCellClass}
            customRowsPerPageOptions={[10]}
          />
        </div>
      </GridItem>

      <GridItem xs={12} container justifyContent={'center'}>
        <Typography variant={'h6'} style={{fontWeight: 600, marginBottom: 16}}>
          <Trans>Phone history</Trans>
        </Typography>
      </GridItem>
      <GridItem xs={12}>
        <div className={classes.overFlowAuto}>
          <Table
            data={phoneHistory}
            columns={columns}
            loading={phoneHistoryLoading}
            onTableColumnSort={handlePhoneTableSort}
            onTableRowClick={handleRowClick}
            tableSort={phoneTableSort}
            page={phonePage}
            meta={phoneHistoryMeta}
            onChangePage={handleChangePhonePage}
            getCellClass={getCellClass}
            customRowsPerPageOptions={[10]}
          />
        </div>
      </GridItem>
    </GridContainer>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPhoneHistory,
      getCarHistory,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      phoneHistory: store.call.phoneHistory,
      phoneHistoryLoading: store.call.phoneHistoryLoading,
      phoneHistoryMeta: store.call.phoneHistoryMeta,
      carHistory: store.call.carHistory,
      carHistoryLoading: store.call.carHistoryLoading,
      carHistoryMeta: store.call.carHistoryMeta,
    }
  }, mapDispatchToProps)
)(EstablishmentHistory)
